<p-dialog header="Gestion Incident Client" [(visible)]="clientTicket" [modal]="true" styleClass="dialog35"
    [baseZIndex]="10000" [draggable]="true" [resizable]="false" [closable]="true" (onHide)="closeModal(false)">
    <p-toast key="clientTicketToast"></p-toast>
    <form [formGroup]="clientTicketForm" (ngSubmit)="submitClientTicket(btnSCT)">
        <div class="row">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Numéro de commande</label>
                <input type="text" class="form-control" formControlName="order_number">
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Numéro de téléphone</label>
                <input type="text" class="form-control" formControlName="client_phone">
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Source</label>
                <select formControlName="source" class="form-select">
                    <option value="" selected>Choisir une source</option>
                    <option *ngFor="let source of sources | keyvalue:otherService.onCompare" [ngValue]="source.key">{{source.value}}</option>
                </select>
                <small class="form-text text-danger"
                        *ngIf="clientTicketForm.controls.source.invalid && clientTicketForm.controls.source.touched && clientTicketForm.controls.source.errors['required']">
                        Obligatoire!
                </small>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Type</label>
                <div *ngIf="types.length == 0" class="spinner-border ms-1"
                    style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" role="status">
                </div>
                <select formControlName="type" class="form-select" (change)="onTypeChange($event)">
                    <option value="" selected>Choisir un type</option>
                    <option *ngFor="let type of types | keyvalue:otherService.onCompare" 
                            [ngValue]="type?.key">
                        {{type.value?.name}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Raison</label>
                <select formControlName="reason" class="form-select">
                    <option value="" selected>Choisir une raison</option>
                    <option *ngFor="let reason of reasons | keyvalue:otherService.onCompare" 
                            [ngValue]="reason.key">
                        {{reason.value?.text}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Description <span class="text-danger">*</span></label>
                <textarea type="text" rows="5" class="form-control" formControlName="description"></textarea>
                <small class="form-text text-danger"
                        *ngIf="clientTicketForm.controls.description.invalid && clientTicketForm.controls.description.touched && clientTicketForm.controls.description.errors['required']">
                        Description obligatoire!
                </small>
            </div>
        </div>

        <!-- <div class="row mt-1">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Rapporter à</label>
                <input type="email" class="form-control" formControlName="report_to">
                <small class="form-text text-danger"
                        *ngIf="clientTicketForm.controls.report_to.invalid && clientTicketForm.controls.report_to.touched && clientTicketForm.controls.report_to.errors['email']">
                        Invalid !
                </small>
            </div>
        </div> -->

        <div class="row mt-1">
            <div class="col-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox"  formControlName="notify_backoffice" id="notify_backoffice_check">
                    <label class="form-check-label dzt-grey-color mb-0" for="notify_backoffice_check">
                        Informer le back-office
                    </label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="notify_fleet" id="notify_fleet_check">
                    <label class="form-check-label dzt-grey-color mb-0" for="notify_fleet_check">
                        Informer le fleet
                    </label>
                  </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn btn-default-fbeeper me-3"
                    (click)="closeModal(false)">Annuler</button>
                <button type="submit" class="btn btn-success-fbeeper" #btnSCT>
                    <div *ngIf="btnSCT.disabled && clientTicketForm.valid" class="spinner-border"
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" role="status">
                    </div>
                    Partager
                </button>
            </div>
        </div>
    </form>
</p-dialog>