import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './delivery.reducer'

const getDeliveryFeatureState = createFeatureSelector<State>('delivery')

export const getCurrentDelivery = createSelector(
    getDeliveryFeatureState,
    (state: any) => state.delivery
)

export const getCurrentError = createSelector(
    getDeliveryFeatureState,
    (state: any) => state.error
)