<p-drawer *ngIf="selectedOrder" [(visible)]="openOrderSidebar" [modal]="modalMode" [styleClass]="fullScreenOrderSidebar? 'dialogRight' : 'dialog40'"
        (onHide)="onSidebarClose()">
    <!-- styleClass="dialog35" -->
    <p-toast key="KeyDashboardSidebar" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
    <ng-template pTemplate="header">
        <div class="row py-2 border-bottom" style="width: 98%;">
            <div class="col-4 d-flex align-items-center">
                <svg *ngIf="selectedOrder?.order?.collected_order" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="var(--green-hub-fbeeper)" class="bi bi-cash-coin me-1 fw-bold" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                    <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                    <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                    <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-shop me-1 fw-bold" viewBox="0 0 16 16"
                        *ngIf="selectedOrder?.order?.created_by_store
                                || (selectedOrder?.errand && selectedOrder?.store)" >
                    <title>créer par le resto</title>
                    <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
                </svg>
                <svg *ngIf="selectedOrder?.order?.planned || selectedOrder?.planned" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--info-hub-fbeeper)" class="bi bi-alarm  me-1" viewBox="0 0 16 16">
                    <title>planifiée</title>
                    <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                </svg>
                <strong> {{selectedOrder?.errand_code || selectedOrder?.order?.order_number?.split('-')[0]}} </strong>
                &nbsp; <p-rating *ngIf="selectedOrder?.rating" [ngModel]="selectedOrder?.rating?.rating" [readonly]="true" [stars]="5"></p-rating>
            </div>
            <div class="col-4 text-center">
                <span *ngIf="!selectedOrder?.errand"  class="p-2 rounded-3" 
                        [ngClass]="{
                            'pistach' : (selectedOrder?.order_status != 'pending' && selectedOrder?.order_status != 'waiting' && (selectedOrder?.status === 'accepted' || selectedOrder?.status === 'pending' )) || selectedOrder?.status === 'accepted',
                            'bgAccepted': (selectedOrder?.order_status != 'pending' &&   selectedOrder?.status == 'order_accepted') || (selectedOrder?.status === 'delivery_accepted'),
                            'bgPickUp': (selectedOrder?.order_status != 'pending' &&  selectedOrder?.status == 'pickup') || (selectedOrder?.status === 'pickup'),
                            'bgWarning':selectedOrder?.order_status == 'pending'  && selectedOrder?.status !='new'  ,
                            'bgNewOrder': selectedOrder?.status ==='new',
                            'bgWaiting': selectedOrder?.order_status == 'waiting' && selectedOrder?.status == 'pending',
                            'bgSuccess':(selectedOrder.status == 'delivered'),
                            'bgDanger':(selectedOrder.status != 'delivered'),
                            'bgLivreur': selectedOrder?.status == 'accepted'
                        }">
                    {{(showHistoryPanel)? (selectedOrder | deliveryStatus) : (selectedOrder | orderStatus) }}
                </span>
                <span *ngIf="selectedOrder?.errand"  class="p-2 rounded-3" 
                        [ngClass]="{
                            'bgLivreur': selectedOrder?.status ==='pending',
                            'bgAccepted': selectedOrder?.status == 'delivery_accepted',
                            'bgSuccess':(selectedOrder?.status == 'completed'),
                            'bgDanger':(selectedOrder?.status == 'canceled'),
                            'bgPickUp':(selectedOrder?.status == 'in-delivery'),
                            'bgWaiting': selectedOrder?.status == 'waiting'
                        }">
                    {{selectedOrder | errandStatus}}
                </span>
            </div>
            <div class="col-4 text-end">
                <button *ngIf="(showHistoryPanel)" 
                        (click)="duplicateOrder()"
                        class="btn btn-outline-secondary px-1 py-0 me-2">
                    Dupliquer
                </button>
                
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="var(--primeng-hub-green)" class="bi bi-map" viewBox="0 0 16 16" 
                    style="cursor: pointer;" (click)="showMap()">
                    <path fill-rule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
                </svg>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="row py-2 border-bottom" *ngIf="(showHistoryPanel || showErrandsPanel)">
            <div class="col-6">
                <strong>Date de Création:</strong> &nbsp; {{selectedOrder?.created?.date | date: "dd/MM/YYYY HH:mm"}}
            </div>
        </div>
        <div class="row py-2 border-bottom">
            <div class="col-3 d-flex align-items-center">
                <svg *ngIf="!selectedOrder?.errand" (click)="storeTicket = true;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class="bi bi-exclamation-triangle-fill me-1" viewBox="0 0 16 16" style="transform: translateY(-1px); cursor: pointer;">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <strong> {{selectedOrder?.errand ? 'Source' : 'Store'}} : &nbsp; </strong>
            </div>
            <div class="col-4 d-flex align-items-center" [ngStyle]="{'color': selectedOrder?.errand ? 'var(--info-hub-fbeeper)' : 'inherit'}">
                <img *ngIf="selectedOrder?.errand && selectedOrder?.store"
                    alt="store" height="20px" width="20px" 
                    style="border-radius: 50%; 
                        padding: 1px; 
                        border-width: 1px; 
                        border-style: solid; 
                        border-color: grey; 
                        border-image: initial;" 
                    [src]="selectedOrder?.store?.logo">
                &nbsp;
                {{selectedOrder?.store?.name || selectedOrder?.order?.store?.name}} &nbsp;
                <span *ngIf="!selectedOrder?.errand"
                    title="Historique des établissements"
                    style="cursor: pointer;"
                    (click)="onHistoryStoreClick({key: 'filter_by_store', 
                                            value: {id: selectedOrder?.order?.store_id,
                                                    name: selectedOrder?.order?.store?.name}})">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                        <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                    </svg>
                </span>
            </div>
            <div class="col-5 d-flex justify-content-end align-items-center">
                <div class="dropdown btn-group dropstart">
                    <button class="btn btn-dropdown-phone dropdown-toggle" style="color: var(--info-hub-fbeeper);" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul class="dropdown-menu">
                        <li class="dropdown-item" style="color: var(--success-hub-fbeeper); cursor: pointer;" (click)="openAdditionalPhonesModal('store')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                            </svg>
                            &nbsp;
                            Ajouter un n° de tel
                        </li>
                        <li class="dropdown-item" 
                            *ngFor="let phone of (currentStores || {})[selectedOrder?.order?.store_id]?.data?.additional_phones || (currentStores || {})[selectedOrder?.store?.id]?.data?.additional_phones
                            | keyvalue:otherService.onCompare">
                            {{phone?.value?.label + ': '}}
                            <a style="color: var(--info-hub-fbeeper); text-decoration: none;" 
                                [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (phone?.value?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                                {{(phone?.value?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}}
                            </a>
                        </li>
                    </ul>
                </div>
                <a class="mx-2" style="color: var(--info-hub-fbeeper); cursor: pointer; text-decoration: none;" 
                    [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (selectedOrder?.order?.store?.phone || selectedOrder?.store?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                    {{(selectedOrder?.order?.store?.phone || selectedOrder?.store?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}}
                </a>
                <a [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (selectedOrder?.order?.store?.phone || selectedOrder?.store?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--success-hub-fbeeper)" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16" style="cursor: pointer;">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </a>
            </div>
        </div>
        
        <div *ngIf="selectedOrder?.errand" class="row py-2 border-bottom">
            <div class="col-12 text-break">
                <strong> Adresse du source: &nbsp; </strong> {{selectedOrder?.from_address || '-'}}
            </div>
        </div>

        <div class="row py-2 border-bottom" *ngIf="!(selectedOrder?.errand && selectedOrder?.store)">
            <div class="col-12">
                <div class="row">
                    <div class="col-3">
                        <svg (click)="clientTicket = true;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class="bi bi-exclamation-triangle-fill me-1" viewBox="0 0 16 16" style="transform: translateY(-1px); cursor: pointer;">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--info-hub-fbeeper)" class="bi bi-info-circle-fill" viewBox="0 0 16 16" title="Lisser un commentaire sur le client" style="transform: translateY(-1px); cursor: pointer;"
                            (click)="hiddenSuspendClient = !hiddenSuspendClient">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                        <strong [ngStyle]="{'color': selectedOrder?.client?.suspended ? 'var(--danger-hub-fbeeper)' : 'inherit'}"> 
                            Client: &nbsp; 
                        </strong> 
                    </div>
                    <div class="col-4">
                        {{selectedOrder?.client?.name}}
                        <img *ngIf="selectedOrder?.order?.gold" src="assets/images/gold.svg" alt="Gold" width="20" height="20">
                        <span *ngIf="selectedOrder?.clientOrdersCount > 0" 
                            [pTooltip]="selectedOrder.clientOrders" tooltipPosition="left" [escape]="false" tooltipZIndex="5000"
                            style="color: var(--info-hub-fbeeper);"
                            class="ms-2">
                            <b> {{selectedOrder?.clientOrdersCount}} </b>
                        </span>
                    </div>
                    <div class="col-5 text-end">
                        <a  style="color: var(--info-hub-fbeeper); text-decoration: none;"
                            [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (selectedOrder?.client?.phone)
                                                    ?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">

                            {{(selectedOrder?.client?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}} &nbsp;
                        </a>
                        <span *ngIf="!selectedOrder?.errand"
                            style="cursor: pointer;" (click)="sendSMSType = 'sms'; isSendSMS = true;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                            </svg>
                        </span> &nbsp;
                        <span *ngIf="!selectedOrder?.errand"
                            style="cursor: pointer;" (click)="sendSMSType = 'notification'; isSendSMS = true;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                            </svg>
                        </span> &nbsp;

                        <a  style="color: var(--info-hub-fbeeper); text-decoration: none;"
                            [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (selectedOrder?.client?.phone)
                                                    ?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--success-hub-fbeeper)" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16" style="cursor: pointer;">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="offset-3 col-5 d-flex justify-content-between">
                        <span style="color: var(--success-hub-fbeeper);" title="Commandes livrées"> {{selectedOrder?.client?.stats?.delivered}} </span>
                        <span style="color: var(--danger-hub-fbeeper);" title="Commandes non livrées"> {{selectedOrder?.client?.stats?.not_delivered}} </span>
                        <span style="color: var(--info-hub-fbeeper);" title="Commandes en cours"> {{selectedOrder?.client?.stats?.pending_delivery}}</span>
                    </div>
                </div>
                <form [formGroup]="clientToSuspendForm"
                    (ngSubmit)="submitClientToSuspend(btnSCTS)"
                    [hidden]="hiddenSuspendClient">
                    <div class="row mt-2">
                        <div class="col-12">
                            <textarea class="form-control" rows="1" placeholder="Commentaire" formControlName="comment"></textarea>
                            <div class="d-flex justify-content-between mt-1">
                                <div class="form-check form-switch">
                                    <label class="form-check-label color-danger-hub-fbeeper" for="flexSwitchCheckSuspend">Suspendre?</label>
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckSuspend" formControlName="suspended" [value]="1">
                                </div>
                                <button #btnSCTS type="submit" class="btn btn-success-fbeeper btn-sm" [disabled]="clientToSuspendForm.invalid">
                                    <div *ngIf="btnSCTS.disabled && clientToSuspendForm.valid" 
                                        class="spinner-border" 
                                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                                        role="status">
                                    </div>
                                    Sauvgarder
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
        <div *ngIf="selectedOrder?.errand"
            class="row py-2 border-bottom">
            <div class="col-3 d-inline-flex align-items-center">
                <strong> Destination: &nbsp; </strong> 
            </div>
            <div class="col-4 d-inline-flex align-items-center">
                {{selectedOrder?.data?.client_name}}
            </div>
            <div class="col-5 text-end">
                <a  style="color: var(--info-hub-fbeeper); text-decoration: none;"
                    [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (selectedOrder?.to_phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                    {{(selectedOrder?.to_phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}} &nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--success-hub-fbeeper)" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16" style="cursor: pointer;">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </a>
            </div>
        </div>

        <div *ngIf="selectedOrder?.errand" class="row py-2 border-bottom">
            <div class="col-12 text-break">
                <strong> Adresse de destination: &nbsp; </strong> {{selectedOrder?.to_address || '-'}}
            </div>
        </div>
    
        <div *ngIf="(selectedOrder?.order?.deliver_at || selectedOrder?.deliver_at)"
            class="row py-2 border-bottom">
            <div class="col-3 d-inline-flex align-items-center">
                <strong> planifiée pour: &nbsp; </strong> 
            </div>
            <div class="col-9 d-inline-flex align-items-center">
                <span *ngIf="((selectedOrder?.order?.deliver_at?.date || selectedOrder?.deliver_at?.date) | date: 'yyyy-MM-dd') 
                    == (toDay | date: 'yyyy-MM-dd')">

                    Aujourd'hui
                </span>
                <span *ngIf="((selectedOrder?.order?.deliver_at?.date || selectedOrder?.deliver_at?.date) | date: 'yyyy-MM-dd') 
                    != (toDay | date: 'yyyy-MM-dd')">

                    {{(selectedOrder?.order?.deliver_at?.date || selectedOrder?.deliver_at?.date) | date: 'dd/MM/yyyy'}}
                </span>
                <b>
                    &nbsp; à: &nbsp; {{(selectedOrder?.order?.deliver_at?.date || selectedOrder?.deliver_at?.date) | date: 'HH:mm'}}
                </b>
            </div>
            <!-- <div class="col-5 fw-bold d-inline-flex align-items-center justify-content-center">
                à: &nbsp; {{selectedOrder?.order?.deliver_at?.date | date: 'HH:mm'}}
            </div> -->
        </div>
    
        <div class="row py-2 border-bottom">
            <div class="col-3 d-inline-flex align-items-center">
                <strong> Livreur: &nbsp; </strong> 
            </div>
            <div class="col-4 d-inline-flex align-items-center">
                <span [ngStyle]="{color: (selectedOrder?.staff && selectedOrder?.status !== 'accepted')? 'var(--success-hub-fbeeper)' : 'var(--info-hub-fbeeper)', float: 'right'}"> 
                    <b> {{selectedOrder?.staff?.name}} </b>
                </span> 
            </div>
            <div class="col-5 d-inline-flex align-items-center justify-content-end" *ngIf="selectedOrder?.staff?.phone">
                <div class="dropdown dropstart">
                    <a class="btn btn-dropdown-phone dropdown-toggle" style="color: var(--info-hub-fbeeper);" role="button" id="dropdownMenuLinkStaffPhone" data-bs-toggle="dropdown" aria-expanded="false"></a>
                  
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLinkStaffPhone">
                        <li class="dropdown-item" style="color: var(--success-hub-fbeeper); cursor: pointer;" (click)="openAdditionalPhonesModal('staff')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                            </svg>
                            &nbsp;
                            Ajouter un n° de tel
                        </li>
                        <li class="dropdown-item" *ngFor="let phone of (currentDelivery || {})[selectedOrder?.staff_id]?.data?.additional_phones | keyvalue:otherService.onCompare">
                            {{phone?.value?.label + ': '}}
                            <a style="color: var(--info-hub-fbeeper); text-decoration: none;" [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (phone?.value?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">{{(phone?.value?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}}</a>
                        </li>
                    </ul>
                </div>
                <a class="mx-2" style="color: var(--info-hub-fbeeper); cursor: pointer; text-decoration: none;" 
                    [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (selectedOrder?.staff?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                    {{(selectedOrder?.staff?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}}
                </a>
                <a style="color: var(--info-hub-fbeeper); text-decoration: none;"
                    [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (selectedOrder?.staff?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--success-hub-fbeeper)" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16" style="cursor: pointer;">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </a>
            </div>
        </div>
        <form   [formGroup]="changeDeliveryForm" 
                (ngSubmit)="selectedOrder?.errand ? submitChangeDelivery(btnSCD, errandsService) : submitChangeDelivery(btnSCD, deliveriesService)"
                *ngIf=" (selectedOrder?.errand && !showErrandsPanel)
                        || (!showHistoryPanel
                            && !showErrandsPanel
                            && (selectedOrder?.status != 'new' 
                                && selectedOrder?.status != 'pickup' 
                                && selectedOrder?.status != 'pending'  
                                && selectedOrder?.order_status != 'in-delivery'))">
            <div class="row py-2 border-bottom">
                <div class="col-3 d-flex align-items-center">
                    <strong> Changer livreur: &nbsp; </strong>
    
                </div>
                <div class="col-6 d-flex align-items-center">
                    <div class="form-check mx-1">
                        <input  class="form-check-input" type="checkbox" value="true" id="all-deliveries-staff"  
                                formControlName="all_delivery"
                                (change)="filterWorkingDelivery()">
                        <label class="form-check-label" for="all-deliveries-staff">
                          Tous
                        </label>
                      </div>
                
                    <p-select class="deliveryList mx-1" [options]="(workingDelivery || [])" formControlName="staff_id" optionLabel="name" optionValue="staff_id" 
                            placeholder="Choisir un livreur" [filter]="true" emptyFilterMessage="Aucun livreur trouvé" emptyMessage="Aucun livreur">
                        <ng-template let-delivery pTemplate="item">
                            <div class="w-100 d-flex" style="justify-content: space-between"
                            [ngStyle]="{color: delivery?.online ? 'var(--green-hub-fbeeper)':'var(--danger-hub-fbeeper)'}">
                                <div>
                                    <span *ngIf="delivery?.working"> 🕑 </span>
                                    <span *ngIf="delivery?.errands">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff622e" class="bi bi-cart4" viewBox="0 0 16 16">
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
                                        </svg>
                                    </span>
                                    <span > {{delivery?.name}} </span>
                                </div>
                                <span *ngIf="(delivery?.distance >= 0) && (delivery?.distance !== null)"> &nbsp; {{delivery?.distance}} Km</span>
                            </div>
                        </ng-template>
                    </p-select>
                    
                </div>
    
                <div class="col-12 col-lg-3 d-flex align-items-center justify-content-end">
                    <button #btnSCD type="submit" class="btn btn-default-green-fbeeper btn-sm">
                        <div *ngIf="btnSCD.disabled && changeDeliveryForm.valid" 
                            class="spinner-border" 
                            style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                            role="status">
                        </div>
                        Valider
                    </button>
                </div>
            </div>
        </form>

        <div *ngIf="!selectedOrder?.errand" class="accordion py-2 border-bottom" id="accordionDeliveryManList">
            <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDeliveryManList" aria-expanded="false" aria-controls="collapseDeliveryManList">
                    Livreur dans le même store
                  </button>
                </h2>
                <div id="collapseDeliveryManList" class="accordion-collapse collapse" data-bs-parent="#accordionDeliveryManList">
                  <div class="accordion-body">
                    <table style="width: 100%;">
                        <thead>
                            <tr>
                                <th>Livreur</th>
                                <th>Num</th>
                                <th>statut</th>
                                <th>Depuis</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let delivery of currentHubManager?.active_deliveries | keyvalue: otherService.onCompare">
                                <tr *ngIf="(delivery?.value?.store_id == selectedOrder?.order?.store_id)
                                            && (delivery?.value?.order_number != selectedOrder?.order?.order_number)">
                                    <td> {{delivery?.value?.name}} </td>
                                    <td> {{delivery?.value?.order_number?.split('-')[0]}} </td>
                                    <td> {{delivery?.value?.delivery_status | orderStatus}} </td>
                                    <td> {{otherService.getTimeDifference(delivery?.value?.created_at, "timer")}} </td>
                                </tr>
                            </ng-container> 
                            <!-- <tr *ngIf="(currentHubManager?.active_deliveries | keyvalue: otherService.onCompare)?.length == 0">
                                <td colspan="4" class="text-center">Aucun livreur dans ce store.</td>
                            </tr> -->
                        </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
        
        <!-- <div class="row py-2 border-bottom" *ngIf="!selectedOrder?.errand">
            <div class="col-12">
                
            </div>
        </div> -->
    
        <div #orderCollected>
            <div class="row py-2 border-bottom" *ngIf="selectedOrder?.order?.collected_order && !selectedOrder?.errand">
                <div class="col-6 text-center" style="color: var(--info-hub-fbeeper);">
                    Commande Collecté
                </div>
                <div class="col-6 text-center">
                    <button type="button" (click)="orderCollectedConfirmation.hidden = false; orderCollected.hidden = true;" class="btn btn-default-fbeeper btn-sm rounded-pill border-1" style="background-color: var(--grey-fbeeper); border-color: var(--gray-fbeeper);">Ne collecter pas</button>
                </div>
            </div>
        </div>
        <div class="row py-2 border-bottom" #orderCollectedConfirmation hidden="true">
            <div class="col-6 d-flex align-items-center justify-content-center" style="color: var(--info-hub-fbeeper);">
                Cette commande ne sera pas collectée!
            </div>
            <div class="col-6 text-center">
                <button type="button" (click)="orderCollectedConfirmation.hidden = true; orderCollected.hidden = false;" class="btn" style="color: var(--gray-hub-fbeeper);">Non</button>
                <button type="button" #btnSNC (click)="submitNoCollect(btnSNC, orderCollectedConfirmation, orderCollected)" class="btn" style="color: var(--danger-hub-fbeeper);">
                    <div *ngIf="btnSNC.disabled" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Oui
                </button>
            </div>
        </div>
    
        <form  *ngIf="!(showHistoryPanel || showErrandsPanel) && selectedOrder?.hubs" #changeHubForm="ngForm" (ngSubmit)="submitChangeHub(btnSCH, changeHubForm)">
            <div class="row py-2 border-bottom">
                <div class="col-3 d-flex align-items-center">
                    <strong> Changer hub: &nbsp; </strong>
                    
                </div>
                <div class="col-5 d-flex align-items-center">
                    <select class="form-select mx-1" style="width: 155px;" aria-label="Default select" name="hub_id" ngModel required>
                        <option value="" selected>Choisir un hub</option>
                        <option *ngFor="let hub of (selectedOrder?.hubs || {}) | keyvalue:otherService.onCompareReverse" 
                                [ngValue]="hub.key">
                            {{hub.value}}
                        </option>
                    </select>
                </div>
    
                <div class="col-4 d-flex align-items-center justify-content-end">
                    <button #btnSCH type="submit" class="btn btn-default-green-fbeeper btn-sm">
                        <div *ngIf="btnSCH.disabled && changeHubForm.valid" 
                            class="spinner-border" 
                            style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                            role="status">
                        </div>
                        Valider
                    </button>
                </div>
            </div>
        </form>
    
        <div class="row py-2 border-bottom" [hidden]="hiddenGMap">
            <div class="col-12">
                <!-- <p-gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'320px'}" (onMapReady)="setMap($event)"></p-gmap> -->
                <google-map #googleMap
                            id="sidebar-map"
                            height="320px"
                            width="100%"
                            mapId="a55c9568c91ab3eb" 
                            [options]="options">
                            @for (position of advancedMarkerPositions; track position; let idx = $index) {
                                <map-advanced-marker [position]="position" background="../../../assets/images/client-icon.png" [options]="advancedMarkerOptions[idx]" />
                            }
                </google-map>
                <!-- <img [src]="mapURL" alt="Map" width="100%" height="400px"> -->
            </div>
        </div>
        <div class="row py-2 border-bottom">
            <div class="col-12" style="max-height: 150px; overflow-y: auto;">
                <div class="row p-1 border-bottom" *ngFor="let status of selectedOrder?.status_history | keyvalue:otherService.onCompare">
                    <div class="col-12">
                        <div class="row" style="font-weight: 600;" [ngStyle]="{color:status?.value?.type == 'order' ? 'var(--info-hub-fbeeper)':'var(--success-hub-fbeeper)'}">
                            <div class="col-3">
                                <button class="btn btn-default-fbeeper" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#status-comment-' + status?.key" aria-expanded="false" aria-controls="status-comment"
                                        *ngIf="status?.value?.comment" style="padding: 0 !important;">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg> -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </button>
                                {{status?.value?.name}}
                            </div>
                            <div class="col-5" [ngStyle]="{color: (status?.value?.action == 'not-accepted') 
                                                                  || (status?.value?.status == 'not-accepted') 
                                                                  ? 'var(--danger-hub-fbeeper)':'inherit'}">
                                {{status?.value?.action || status?.value?.status}}
                            </div>
                            <div class="col-4 text-end">
                                {{status?.value?.time}}
                            </div>
                        </div>
                        <div class="row" *ngIf="status?.value?.comment">
                            <div class="col-12">
                                <div class="collapse" id="status-comment-{{status?.key}}">
                                    <div class="card card-body" style="padding: 5px !important;">
                                        {{status?.value?.comment}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!selectedOrder?.errand" class="row py-2 border-bottom">
            <div class="col-4 text-center">
                <strong> Temps de livraison: &nbsp; </strong> <span style="color: var(--info-hub-fbeeper);"> {{selectedOrder?.delivery_time}}min </span>
            </div>
            <div class="col-4 text-center" *ngIf="showHistoryPanel">
                <strong> Temps de livraison actuel: &nbsp; </strong> <span style="color: var(--info-hub-fbeeper);"> {{otherService.avgDeliveryTime(+selectedOrder?.real_delivery_time * 1000 ) || "-"}} </span>
            </div>
            <div class="col-4 text-center" *ngIf="showHistoryPanel">
                <strong> Temps d'affectation: &nbsp; </strong> <span style="color: var(--info-hub-fbeeper);"> {{otherService.avgDeliveryTime(selectedOrder?.affectation_time * 1000 ) || "-"}} </span>
            </div>
        </div>
        <div class="row py-2 border-bottom" *ngIf="(!showHistoryPanel || (showHistoryPanel && selectedOrder?.status == 'not-delivered')) 
                                                    &&  selectedOrder?.status != 'pending' 
                                                    &&  selectedOrder?.status != 'new'
                                                    &&  !selectedOrder?.errand">
            <div class="col-3">
                <strong> Statut de livraison: &nbsp; </strong>
            </div>
            <div class="col-4">
                <b> <span style="color: var(--info-hub-fbeeper);"> {{selectedOrder | deliveryStatus }} </span> </b>
            </div>
            <div class="col-5 text-end">
                <button type="button" class="btn btn-success-fbeeper btn-sm"  (click)="statusService = deliveriesService; changeStatusDeliveriesModal = true">Changer</button>
            </div>
        </div>
        <div class="row py-2 border-bottom" *ngIf="!selectedOrder?.errand">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="accordion" id="accordionItems">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOrderItems">
                                  <button class="green-hub accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOrderItems" aria-expanded="true" aria-controls="collapseOrderItems">
                                    Items
                                  </button>
                                </h2>
                                <div id="collapseOrderItems" class="accordion-collapse collapse show" aria-labelledby="headingOrderItems" data-bs-parent="#accordionItems">
                                  <div class="accordion-body">
                                    <div class="accordion" id="accordionItem">
                                        <div *ngFor="let item of selectedOrder?.order?.items | keyvalue:otherService.onCompare">
                                            <div class="success-hub accordion-item mt-1" *ngIf="((item?.value?.data?.options | keyvalue)?.length || 0) > 0">
                                                <div class="w-100 d-flex justify-content-between">
                                                    <div class="d-flex">
                                                        <div class="accordion-header" id="heading-{{item?.key}}">
                                                            <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" 
                                                                [attr.data-bs-target]="'#collapse-' + item?.key" aria-expanded="false" [attr.aria-controls]="'collapse-' + item?.key">
                                                            </button>
                                                        </div>
                                                        <span style="font-size: inherit; color: var(--green-hub-fbeeper);" class="fw-bold">
                                                            {{
                                                                (item?.value?.unit == "kg" ? item?.value?.original_quantity : item?.value?.quantity) 
                                                                + (item?.value?.unit == "kg" ? " kg " : " x ") 
                                                                + item?.value?.title 
                                                                + (item?.value?.unit == "ukg" ? " ("+item?.value?.quantity+" Kg) " : "")
                                                            }} 
                                                        </span>
                                                    </div>
                                                    <span class="fw-bold" style="flex-shrink: 0; float: right;">{{item?.value?.price | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}</span>
                                                </div>
                                                <div id="collapse-{{item?.key}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading-' + item?.key">
                                                  <div class="accordion-body">
                                                        <div class="row">
                                                            <div class="col-9">
                                                                Prix de base {{item?.value?.unit != 'u'? "d'un kg" : ""}}: 
                                                            </div>
                                                            <div class="col-3 text-end">
                                                                {{item?.value?.data?.base_price | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
                                                            </div>
                                                        </div>
                                                        <div class="row" *ngFor="let option of item?.value?.data?.options | keyvalue:otherService.onCompare">
                                                            <div class="col-9">
                                                                {{option?.value?.name}}
                                                            </div>
                                                            <div class="col-3 text-end">
                                                                {{option?.value?.price | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
                                                            </div>
                                                        </div>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="row mt-1 fw-bold" *ngIf="((item?.value?.data?.options | keyvalue)?.length || 0) == 0">
                                                <div class="col-9" style="color: var(--success-hub-fbeeper); padding-left: 2rem;">
                                                    {{(item?.value?.unit == "ukg" ? item?.value?.original_quantity : item?.value?.quantity) + (item?.value?.unit == "kg" ? " kg " : " x ") + item?.value?.title + (item?.value?.unit == "ukg" ? " ("+item?.value?.quantity+" Kg) " : "")}} 
                                                </div>
                                                <div class="col-3 text-end">
                                                    {{item?.value?.price | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <ng-container *ngIf="selectedOrder?.errand">
            <div *ngFor="let field of selectedOrder?.display_fields?.hub"
                class="row py-2 border-bottom">
                <div class="col-3 d-inline-flex align-items-center">
                    <strong> {{field?.label}}: &nbsp; </strong> 
                </div>
                <div class="col-9">
                    <p class="mb-0 text-break">
                        <b> <span style="color: var(--info-hub-fbeeper);"> {{field?.value || '-'}} </span></b>
                    </p>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedOrder?.errand" class="border-bottom">
            <div class="row py-2">
                <div class="col-3 d-inline-flex align-items-center">
                    <strong> Instructions: &nbsp; </strong> 
                </div>
                <div class="col-9">
                    <p class="mb-0 text-break">
                        {{selectedOrder?.instructions || '-'}}
                    </p>
                </div>
            </div>
            <ng-container *ngFor="let file of audio_files">
                <div class="row py-2">
                    <div class="col-12 d-inline-flex align-items-center">
                        <audio controls>
                            <source [src]="file" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="selectedOrder?.errand">
            <div class="row py-2 border-top">
                <div class="col-3 d-inline-flex align-items-center">
                    <strong> Description: &nbsp; </strong> 
                </div>
                <div class="col-9">
                    <p class="mb-0 text-break">
                        {{selectedOrder?.description || '-'}}
                    </p>
                </div>
            </div>
            <div class="row py-2" *ngIf="(img_files || []).length != 0">
                <div class="col-12">
                    <p-carousel [value]="img_files" [numVisible]="3" [numScroll]="3" [circular]="false" 
                                [responsiveOptions]="responsiveOptions" [style]="{width: '100%'}">
                        <ng-template let-product pTemplate="item">
                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                    <div class="mb-3">
                                        <p-image [src]="product" alt="Image" width="100" height="100" appendTo="body" [preview]="true"></p-image>
                                    </div>
                                </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </ng-container>
    
        <div class="row py-2 border-bottom border-top">
            <div class="col-12 text-break">
                <strong> Commentaire: &nbsp; </strong> {{selectedOrder?.order?.comment || '-'}}
            </div>
        </div>
        <div *ngIf="!selectedOrder?.errand" class="row py-2 border-bottom">
            <div class="col-12 text-break">
                <strong> Adresse: &nbsp; </strong> {{selectedOrder?.address || '-'}}
            </div>
        </div>
        <div class="row py-2 border-bottom" *ngIf="showHistoryPanel">
            <div class="col-12 text-break">
                <strong> Avis du client: &nbsp; </strong> {{selectedOrder?.rating?.comment || '-'}}
            </div>
        </div>
        <div class="row py-2 border-bottom" *ngIf=" !selectedOrder?.errand
                                                    && (selectedOrder.status == 'pending' 
                                                        && selectedOrder.order_status !== 'confirmed' 
                                                        && selectedOrder.order_status !== 'waiting'
                                                    )">
            <div class="col-6 text-center">
                <button #btnCSR type="button" class="btn btn-danger-hub-fbeeper btn-sm" (click)="changeOrderStatus(btnCSR, 'rejected')">
                    <div *ngIf="btnCSR.disabled" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Refuser
                </button>
            </div>
            <div class="col-6 text-center">
                <button #btnCSC type="button" class="btn btn-success-fbeeper btn-sm" (click)="changeOrderStatus(btnCSC, 'confirmed')">
                    <div *ngIf="btnCSC.disabled" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Confirmer
                </button>
            </div>
        </div>
        <div class="row py-2 border-bottom" *ngIf="!showHistoryPanel
                                                    && (
                                                        (selectedOrder.status != 'pending' 
                                                            || (selectedOrder.status == 'pending' 
                                                                && (selectedOrder.order_status == 'confirmed' || selectedOrder.order_status == 'waiting') 
                                                        )) 
                                                        && selectedOrder.status != 'pickup'
                                                    )
                                                    && !selectedOrder?.errand">
            <div class="col-3">
                <strong> Statut de commande: &nbsp; </strong> 
            </div>
            <div class="col-4">
                <b> <span style="color: var(--info-hub-fbeeper);"> {{selectedOrder | orderStatusDetail }} </span> </b>
            </div>
            <div class="col-5 text-end">
                <button type="button" class="btn btn-success-fbeeper btn-sm" (click)="statusService = ordersService; changeStatusModal = true">Changer</button>
            </div>
        </div>
        <div class="row py-2 border-bottom" *ngIf="selectedOrder?.errand">
            <div class="col-3">
                <strong> Statut de commande: &nbsp; </strong> 
            </div>
            <div class="col-4">
                <b> <span style="color: var(--info-hub-fbeeper);"> {{selectedOrder | errandStatus }} </span> </b>
            </div>
            <div class="col-5 text-end">
                <button type="button" class="btn btn-success-fbeeper btn-sm" (click)="changeStatusErrandModal = true">Changer</button>
            </div>
        </div>
        <div class="row py-2 border-bottom">
            <div class="col-3">
                <strong> Paiement établissement: </strong> 
            </div>
            <div class="col-4">
                
            </div>
            <div class="col-5 text-end">
                <b [style]="{color: (+selectedOrder?.order?.store_price == (
                            +selectedOrder?.order?.price || +selectedOrder?.client_price))? '' : 'var(--info-hub-fbeeper)'}">
                    {{selectedOrder?.order?.store_price
                        | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code
                    }}
                </b>
            </div>
        </div>
        <div class="row p-3 text-white" style="background-color: var(--green-hub-fbeeper);">
            <div class="col-12">
                <!-- <div class="row">
                    <div class="col-6">
                        Mode de paiement:
                    </div>
                    <div class="col-6 text-end fw-bold" [style]="{'color': selectedOrder?.order?.payment_status ? '' : 'var(--danger-hub-fbeeper)'}">
                        {{selectedOrder?.order?.payment_method == 0 ? 'Espèces' 
                            : selectedOrder?.order?.payment_method == 1 ? 'Argent mobile': 'Carte'}}
                    </div>
                </div>
                <div *ngIf="selectedOrder?.order?.payment_method == 2" class="row">
                    <div class="col-6">
                        Statut du paiement:
                    </div>
                    <div class="col-6 text-end fw-bold" [style]="{'color': selectedOrder?.order?.invoice_status?.status == 'success' ? '' : 'var(--danger-hub-fbeeper)'}">
                        {{selectedOrder?.order?.invoice_status?.status}}
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-6">
                        Prix:
                    </div>
                    <div class="col-6 text-end">
                        {{selectedOrder?.order?.price || selectedOrder?.client_price
                            | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code
                        }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        Remise: &nbsp;
                        <button style="color: white; background-color: transparent; border: solid 1px white; border-radius: 10px;"
                        (click)="openPromosModal()">
                            <small>Promos</small>
                        </button>
                    </div>
                    <div class="col-6 text-end">
                        {{selectedOrder?.discount | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        Frais de service:
                    </div>
                    <div class="col-6 text-end">
                        {{selectedOrder?.order?.service_fee | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code || 0}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        Livraison:
                    </div>
                    <div class="col-6 text-end">
                        {{selectedOrder?.price | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        Total: &nbsp;
                        <button style="color: white; background-color: transparent; border: solid 1px white; border-radius: 10px;"
                        (click)="openTransactionsModal()">
                            <small>Transactions</small>
                        </button>
                    </div>
                    <div class="col-6 text-end">
                        {{(selectedOrder?.client_price ? selectedOrder?.full_price + selectedOrder?.client_price : selectedOrder?.full_price)
                            | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code
                        }}
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- <ng-template pTemplate="footer">
    </ng-template> -->
</p-drawer>

<app-additional-phones  *ngIf="additionalPhonesModal"
                        [additionalPhonesModal]="additionalPhonesModal" 
                        [dataTable]="dataTable"
                        [targetId]="targetId"
                        [type]="selectedType"
                        [additionalPhonesChanges]="additionalPhonesChanges"
                        (additionalPhonesModalEvent)="additionalPhonesModalEvent()">
</app-additional-phones>

<!-- Change status -->
<p-dialog header="Changer le statut" [(visible)]="changeStatusModal" [modal]="true" [style]="{width: '35vw'}" [baseZIndex]="10000" 
        [draggable]="true" [resizable]="false" [closable]="true" (onHide)="closeChangeStatusModal()">
    <p-toast key="KeyChangeStatus" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}" [baseZIndex]="1000"></p-toast>
    <form  [formGroup]="changeStatusForm" (ngSubmit)="submitUpdateStatus(btnSCS, changeStatusForm)">
        <div class="row">
            <div class="col-12">
                <label for="changeStatus">Statut <span class="text-danger">*</span></label>
                <select id="changeStatus" class="form-select" aria-label="Default select" formControlName="status" [(ngModel)]="statusValue">
                    <option value="" disabled selected hidden>Sélectionner un statut</option>
                    <option *ngIf="statusValue == 'rejected'" value="rejected">Refuser</option>
                    <option *ngIf="statusValue !== 'rejected'" value="canceled">Annulée</option>
                    <option *ngIf="(statusValue !== 'rejected')" value="accepted">Acceptée</option>
                </select>
            </div>
        </div>
        <div class="row" *ngIf="(statusValue == 'rejected') || (statusValue == 'canceled')">
            <div class="col-12">
                <label for="changeStatus">Raison <span class="text-danger">*</span></label>
                <select id="changeStatus" class="form-select" aria-label="Default select" formControlName="reasonValue" [(ngModel)]="reasonValue" (change)="onChangeReasonValue()">
                    <option value="" disabled selected hidden>Choisir la raison</option>
                    <option value="Client injoignable pour confirmation">Client injoignable pour confirmation</option>
                    <option value="Client suspendu">Client suspendu</option>
                    <option value="Double commande">Double commande</option>
                    <option value="Etablissement fermé">Etablissement fermé</option>
                    <option *ngIf="statusValue == 'canceled'" value="Etablissement n'a pas répondu">Etablissement n'a pas répondu</option>
                    <option *ngIf="statusValue == 'canceled'" value="Etablissement en surcharge">Etablissement en surcharge</option>
                    <option value="Fin de service">Fin de service (Hub) </option>
                    <option *ngIf="statusValue == 'canceled'" value="Fin de service établissement">Fin de service établissement</option>
                    <option *ngIf="statusValue == 'canceled'" value="Problème technique chez établissement">Problème technique chez établissement</option>
                    <option *ngIf="statusValue == 'canceled'" value="Produit non disponible">Produit non disponible </option>
                    <option *ngIf="statusValue == 'canceled'" value="Supplément non disponible">Supplément non disponible </option>
                    <option *ngIf="statusValue == 'canceled'" value="Suit à la demande de client">Suite à la demande du client</option>
                    <option value="Autre">Autre</option>
                </select>
            </div>
        </div>
        <div class="row mt-2" *ngIf="(statusValue == 'canceled') && (reasonValue === 'Produit non disponible')">
            <div class="col-12" formArrayName="product_unavailable">
                <label for="changeStatusComment">Produit non disponible</label><br>
                <div class="form-check form-check-inline" *ngFor="let item of selectedOrder?.order?.items | keyvalue:otherService.onCompare">
                    <input class="form-check-input" type="checkbox" id="add-option-{{item?.key}}" [value]="item?.value?.title" (change)="onCheckboxChangeComment($event, changeStatusForm, 'product_unavailable')">
                    <label class="form-check-label dzt-grey-color" for="add-option-{{item?.key}}">
                        {{item?.value?.title}} 
                    </label>
                </div>
            </div>
        </div>
        <ng-container>
            <div class="row mt-2" *ngIf="(statusValue == 'canceled') && (reasonValue === 'Supplément non disponible')">
                <div class="col-12" formArrayName="supplement_unavailable">
                    <label for="changeStatusComment">Supplément non disponible</label><br>
                    <ng-container *ngFor="let item of selectedOrder?.order?.items | keyvalue:otherService.onCompare">
                        <div class="form-check form-check-inline" 
                            *ngFor="let option of item?.value?.data?.options | keyvalue:otherService.onCompare">
                            <input class="form-check-input" type="checkbox" id="add-option-{{option?.key}}" 
                                [value]="option?.value?.name" 
                                (change)="onCheckboxChangeComment($event, changeStatusForm, 'supplement_unavailable')">
                            <label class="form-check-label dzt-grey-color" for="add-option-{{option?.key}}">
                                {{option?.value?.name}}
                            </label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="((statusValue == 'rejected') || (statusValue == 'canceled')) 
                                && ((reasonValue === 'Etablissement fermé') 
                                    || (reasonValue === 'Etablissement n\'a pas répondu') 
                                    || (reasonValue === 'Etablissement en surcharge')
                                    || (reasonValue === 'Problème technique chez établissement'))">

            <div class="row mt-2">
                <div class="col-12">
                    <div class="form-group">
                        <label for="changeStatusComment">Mettre l'établissement en pause</label><br>
                        <div class="form-check form-check-inline mt-1">
                            <input class="form-check-input" type="radio" formControlName="store_pause" id="store_pause_10m" value="10">
                            <label class="form-check-label" for="store_pause_10m">10 minutes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="store_pause" id="store_pause_30m" value="30">
                            <label class="form-check-label" for="store_pause_30m">30 minutes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="store_pause" id="store_pause_1h" value="60">
                            <label class="form-check-label" for="store_pause_1h">1 heure</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="store_pause" id="store_pause_3h" value="180">
                            <label class="form-check-label" for="store_pause_3h">3 heures</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="store_pause" id="store_pause_24h" value="600">
                            <label class="form-check-label" for="store_pause_24h">Demain</label>
                        </div>
                        </div>
                </div>
            </div>
        </ng-container>
        <div class="row mt-2" *ngIf="(statusValue == 'accepted') || (reasonValue == 'Autre')">
            <div class="col-12">
                <label for="changeStatusComment">Commentaire</label>
                <textarea class="form-control" id="changeStatusComment" formControlName="comment" rows="3"></textarea>
            </div>
        </div>
        <ng-container *ngIf="((statusValue == 'rejected') || (statusValue == 'canceled')) 
                                && ((reasonValue === 'Etablissement fermé') 
                                    || (reasonValue === 'Etablissement n\'a pas répondu') 
                                    || (reasonValue === 'Etablissement en surcharge')
                                    || (reasonValue === 'Produit non disponible')
                                    || (reasonValue === 'Supplément non disponible')
                                    || (reasonValue === 'Fin de service établissement')
                                    || (reasonValue === 'Problème technique chez établissement')
                                    || (reasonValue === 'Autre'))">
            <div class="row mt-2">
                <div class="col-12">
                    <label for="changeStatusComment">Réclamation</label><br>
                    <div class="form-check">
                        <input  class="form-check-input" type="checkbox" value="true" id="send-store-ticket" formControlName="store_ticket">
                        <label class="form-check-label" for="send-store-ticket">
                          Envoyer une réclamation à l'administration
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="changeStatusForm.get('store_ticket').value">
            <div class="row mt-2">
                <div class="col-12">
                    <label for="storeTicketDetails">Plus détails</label>
                    <textarea class="form-control" id="storeTicketDetails" formControlName="store_ticket_details" rows="2"></textarea>
                </div>
            </div>
        </ng-container>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn btn-default-fbeeper mr-3" (click)="changeStatusModal = false;">Annuler</button>
                <button #btnSCS type="submit" class="btn btn-success-fbeeper" [disabled]="changeStatusForm.invalid">
                    <div *ngIf="btnSCS.disabled && changeStatusForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Envoyer
                </button>
            </div>
        </div>
    </form>
</p-dialog>

<!-- Change status -->
<p-dialog header="Changer le statut" [(visible)]="changeStatusDeliveriesModal" [modal]="true" [style]="{width: '35vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true" (onHide)="statusService = null; statusValue = '';">
    <form #changeStatusDeliveriesForm="ngForm" (ngSubmit)="submitUpdateStatus(btnSCSD, changeStatusDeliveriesForm)">
        <div class="row">
            <div class="col-12">
                <label for="changeStatusDeliveries">Statut <span class="text-danger">*</span></label>
                <select id="changeStatusDeliveries" class="form-select" aria-label="Default select" name="status" ngModel [(ngModel)]="statusValue" required>
                    <option value="" disabled selected hidden>Sélectionner un statut</option>
                    <option *ngIf="(selectedOrder?.staff) && !showHistoryPanel" value="delivery_accepted">Acceptée</option>
                    <option *ngIf="showHistoryPanel" value="canceled">Annulée</option>
                    <option value="not-delivered">Non livrée</option>
                    <option value="delivered">Livrée</option>
                </select>
            </div>
        </div>
        <div class="row mt-2" *ngIf="statusValue !== 'not-delivered'">
            <div class="col-12">
                <label for="changeStatusDeliveriesComment">Commentaire <span class="text-danger">*</span></label>
                <textarea class="form-control" id="changeStatusDeliveriesComment" name="comment" rows="3" ngModel></textarea>
            </div>
        </div>
        <div class="row mt-2" *ngIf="statusValue === 'not-delivered'">
            <div class="col-12">
                <label for="changeStatusDeliveriesReason">Raison <span class="text-danger">*</span></label>
                <textarea class="form-control" id="changeStatusDeliveriesReason" name="reason" rows="3" ngModel required></textarea>
            </div>
        </div>
        <div class="row mt-2" *ngIf="statusValue === 'not-delivered'">
            <div class="col-12">
                <label for="changeStatusDeliveriesLocation">Où est la commande <span class="text-danger">*</span></label>
                <textarea class="form-control" id="changeStatusDeliveriesLocation" name="location" rows="3" ngModel required></textarea>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn btn-default-fbeeper mr-3" (click)="changeStatusDeliveriesModal = false;">Annuler</button>
                <button #btnSCSD type="submit" class="btn btn-success-fbeeper" [disabled]="changeStatusDeliveriesForm.invalid">
                    <div *ngIf="btnSCSD.disabled && changeStatusDeliveriesForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Envoyer
                </button>
            </div>
        </div>
    </form>
</p-dialog>

<!-- Change errand status -->
<p-dialog header="Changer le statut" [(visible)]="changeStatusErrandModal" [modal]="true" [style]="{width: '35vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true">
    <form #changeStatusErrandForm="ngForm" (ngSubmit)="submitUpdateErrandStatus(btnSCES, changeStatusErrandForm)">
        <div class="row">
            <div class="col-12">
                <label for="changeStatusErrand">Statut <span class="text-danger">*</span></label>
                <select id="changeStatusErrand" class="form-select" aria-label="Default select" name="status" ngModel required [(ngModel)]="statusValue">
                    <option value="" disabled selected hidden>Sélectionner un statut</option>
                    <option *ngIf="(selectedOrder?.staff) && !showHistoryPanel" value="delivery_accepted">Acceptée</option>
                    <option *ngIf="!showHistoryPanel" value="completed">terminée</option>
                    <option *ngIf="!showHistoryPanel" value="canceled">Annulée</option>
                </select>
            </div>
        </div>
        <div class="row mt-2" *ngIf="(statusValue == 'canceled')">
            <div class="col-12">
                <label for="changeStatus">Raison <span class="text-danger">*</span></label>
                <select id="changeStatus" class="form-select" aria-label="Default select" name="status_comment" ngModel [(ngModel)]="reasonValue">
                    <option value="" disabled selected hidden>Choisir la raison</option>
                    <option value="Client injoignable pour confirmation">Client injoignable pour confirmation</option>
                    <option value="Produits Interdits">Produits Interdits</option>
                    <option value="Merci de commander d'un établissement partenaire">Merci de commander d'un établissement partenaire</option>
                    <option value="Etablissement déjà existant">Etablissement déjà existant</option>
                    <option value="Produit non disponible">Produit non disponible </option>
                    <option value="Service Indisponible">Service Indisponible</option>
                    <option value="Service surchargé, Merci de repasser ultérieurement">Service surchargé, Merci de repasser ultérieurement</option>
                    <option value="Hors zone de nos services de livraison">Hors zone de nos services de livraison</option>
                    <option value="Suite à la demande du client">Suite à la demande du client</option>
                    <option value="Double commande">Double commande</option>
                    <option value="Autre">Autre</option>
                </select>
            </div>
        </div>
        <div class="row mt-2" *ngIf="(statusValue == 'delivery_accepted') || (reasonValue == 'Autre')">
            <div class="col-12">
                <label for="changeStatusErrandComment">Commentaire</label>
                <textarea class="form-control" id="changeStatusErrandComment" name="comment" rows="3" ngModel></textarea>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn btn-default-fbeeper mr-3" (click)="changeStatusErrandModal = false;">Annuler</button>
                <button #btnSCES type="submit" class="btn btn-success-fbeeper" [disabled]="changeStatusErrandForm.invalid">
                    <div *ngIf="btnSCES.disabled && changeStatusErrandForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Envoyer
                </button>
            </div>
        </div>
    </form>
</p-dialog>

<!-- Promos Modal -->
<p-dialog header="Liste des promos disponible" [(visible)]="promosModal" [modal]="true" [style]="{width: '45vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true" (onHide)="promos = [];">
    <p-toast key="KeyPromosModal" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
    <div class="row">
        <div class="col-12">
            <p-table [value]="promos" [loading]="loadingPromos" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Description</th>
                        <th class="text-center">Valeur</th>
                        <th class="text-center">Prix minimum</th>
                        <th>Stores</th>
                        <th>Region</th>
                        <th class="text-center">Operation</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-promo>
                    <tr>
                        <td>{{promo.description}}</td>
                        <td class="text-center">{{promo.value}} %</td>
                        <td class="text-center">{{promo.min_price}}</td>
                        <td>
                            <span *ngFor="let store of promo.limit_stores">{{store?.name}}</span>
                        </td>
                        <td>
                            <span *ngFor="let region of promo.limit_regions">{{region?.name}}</span>
                        </td>
                        <td class="text-center">
                            <button style="color: var(--info-hub-fbeeper); background: none; border: none;" (click)="applyPromo(promo)">
                                UTILISER
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6" class="text-center">Aucun promo trouvé</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>

<!-- Transactions Modal -->
<p-dialog header="Liste des dernières transactions" [(visible)]="transactionsModal" [modal]="true" [style]="{width: '45vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true" (onHide)="transactions = [];">
    <p-toast key="KeyTransactionsModal" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
    <div class="row">
        <div class="col-12">
            <p-table [value]="transactions" [loading]="loadingTransactions" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">Montant</th>
                        <th class="text-center">Crédit</th>
                        <th class="text-center">Commentaire</th>
                        <th class="text-center">Date</th>
                        <th class="text-center">Livreur</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col style="width: 50px">
                        <col style="width: 50px">
                        <col style="width: 150px">
                        <col style="width: 50px">
                        <col style="width: 50px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="body" let-transaction>
                    <tr>
                        <td class="text-center">{{transaction?.amount}}</td>
                        <td class="text-center">{{transaction?.balance}}</td>
                        <td class="text-center">{{transaction?.comment}}</td>
                        <td class="text-center">{{transaction?.created?.date | date: "dd/MM/yyyy"}}</td>
                        <td class="text-center">{{transaction?.staff?.name}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5" class="text-center">Aucune transaction trouvée</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 text-end">
            <strong> Crédit: {{transactionsCredit | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}} </strong>
        </div>
    </div>
</p-dialog>

<app-store-ticket   *ngIf="storeTicket"
                    [storeTicket]="storeTicket"
                    [store_id]="selectedOrder?.order?.store_id"
                    [order_number]="selectedOrder?.order?.order_number"
                    (storeTicketEvent)="changeStoreTicket($event)">
</app-store-ticket>

<app-send-sms   *ngIf="isSendSMS"
                [type]="sendSMSType"
                [isSendSMS]="isSendSMS"
                [selectedOrder]="selectedOrder"
                (closeSendSMSEvent)="closeSendSMSEvent($event)">

</app-send-sms>

<app-client-ticket   *ngIf="clientTicket"
                    [clientTicket]="clientTicket"
                    [selectedOrder]="selectedOrder"
                    (clientTicketEvent)="changeClientTicket($event)">
</app-client-ticket>