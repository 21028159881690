import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})

export class ClientTicketService {

  constructor(private httpserviceService: HttpserviceService) { }

  createClientTicket(data: any){
    return this.httpserviceService.post(data, "client_ticket");
  }

  getClientsTickets(page: number, params: any){
    return this.httpserviceService.get("client_tickets?page=" + page + params);
  }

  getTicket(id: number){
    return this.httpserviceService.get("client_ticket/" + id);
  }

  getTicketInfo(){
    return this.httpserviceService.get("client_tickets_info");
  }

  updateClientTicket(data: any, id: number){
    return this.httpserviceService.post(data, "client_ticket/" + id);
  }
}
