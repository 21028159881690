import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class ErrandsService {

  constructor(private httpserviceService: HttpserviceService) { }

  createErrands(data: any){
    return this.httpserviceService.post(data, "errand/");
  }
  
  updateDataById(data: any, order_id: number){
    return this.httpserviceService.post(data, "errand/" + order_id );
  }

  getErrands(page: number, params = ""){
    return this.httpserviceService.get("errands?page=" + page + "&order_by=-completed&full_details=1&filters=" + params );
  }
}
