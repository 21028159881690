import { Injectable, inject } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { select, Store } from '@ngrx/store';
import * as fromUser from 'src/app/ngrx/user/user.selectors'
import * as fromHub from 'src/app/ngrx/hub/hub.selectors'
import { getDatabase, ref, onValue } from "firebase/database";
import { Database, fromRef, list, listVal, object, objectVal, onChildAdded } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  currentUser: any = {}
  currentHub: any = {}
  onlineVisitors: AngularFireObject<any> = <any>{};
  private database = inject(Database);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private store: Store,
  ) { 

    this.store.pipe(select(fromUser.getCurrentUser)).subscribe(
      (user: any) =>{
        this.currentUser = user;
      }
    )

    this.store.pipe(select(fromHub.getCurrentHub)).subscribe(
      (user: any) =>{
        this.currentHub = user;
      }
    )
    
  }

  /* getStatusHubManagerZones(region_id: any, hub_id: any): AngularFireList<any> {
    return this.angularFireDB.list("/status/" + region_id + "/" + hub_id + "/hub_manager/delivery_zones/");
  } */

  /* getFirebaseConnection(): AngularFireObject<any> {
    return this.angularFireDB.object(".info/connected/");
  } 
    getOnlineVisitors(hub_id: number): AngularFireObject<any> {
    return this.angularFireDB.object("/online_visitors/hub_id_" + hub_id);;
  } 

  getActiveErrands(region_id: any = this.currentHub?.profile?.hub?.region?.id, hub_id: any = this.currentHub?.profile?.hub?.id): AngularFireObject<any> {
    return this.angularFireDB.object("/active_errands/" + region_id + "/" + hub_id);
  } 
  */

  getFirebaseConnection(){
    return objectVal(ref(this.database, ".info/connected"));
  }

  getOnlineVisitors(hub_id: number): Observable<any>{
    const dbRef = ref(this.database, '/online_visitors/hub_id_' + hub_id);
    return objectVal(dbRef);
  }

  getStatus(region_id: any, hub_id: any): Observable<any> {
    return objectVal(ref(this.database, "/status/" + region_id + "/" + hub_id));
  }

  getActiveDeliveries(region_id: any, hub_id: any): Observable<any> {
    return objectVal(ref(this.database, "/active_deliveries/" + region_id + "/" + hub_id));
  }

  getOnlineStoresByRegion(region_id: any): Observable<any> {
    return objectVal(ref(this.database, "/online/" + region_id + "/stores/"));
  }

  getSMSSendStatus(): Observable<any> {
    return listVal(ref(this.database, "/sms_send/status"));
  }

  getSMSSendReceivedSMS(): Observable<any> {
    return objectVal(ref(this.database, "/sms_send/received_sms"));
  }

  /* removeHubManagerReceivedSMS(region_id: any, hub_id: any, key: any){
    list("/status/" + region_id + "/" + hub_id + "/hub_manager/received_sms/" + key).remove();
  } */

  getStatusDelivery() {
    return ref(this.database, "/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/delivery/");
  }

  getStatusDeliveryPositions() {
    return ref(this.database, "/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/delivery_positions/");
  }

  getStatusHubManager(): Observable<any> {
    return objectVal(ref(this.database, "/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/hub_manager/"));
  }

  getStatusStores(): Observable<any> {
    return objectVal(ref(this.database, "/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/stores/"));
  }

  getActiveErrands(region_id: any = this.currentHub?.profile?.hub?.region?.id, hub_id: any = this.currentHub?.profile?.hub?.id): Observable<any> {
    const dbRef = ref(this.database, "/active_errands/" + region_id + "/" + hub_id);
    return objectVal(dbRef);
  }

}
