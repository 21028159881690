import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'weekdays' })
export class WeekdaysPipe implements PipeTransform {

  transform(day: any): any {
    let statusText = day
    switch (day) {
      case '0':
        statusText = "Dimanche";
        break;
      case "1":
        statusText = 'Lundi';
        break;
      case "2":
        statusText = "Mardi";
        break;
      case "3":
        statusText = "Mercredi";
        break;
      case '4':
        statusText = "Jeudi";
        break;
      case '5':
        statusText = "Vendredi";
        break;
      case '6':
        statusText = "Samedi";
        break;
      default:
        statusText = day;

    }
    return statusText;
  }

}
