import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'errandStatus' })
export class ErrandStatusPipe implements PipeTransform {

  transform(order: any): any {
    let statusText = order?.status
    switch (order?.status) {
      case 'waiting':
        statusText = 'Planifiée';
        break;
      case 'pending':
        statusText = "En attente livreur";
        break;
      case "delivery_accepted":
        statusText = 'Acceptée';
        break;
      case "in-delivery":
        statusText = "En livraison";
        break;
      case "completed":
        statusText = 'Terminée';
        break;
      case "canceled":
        statusText = 'Annulée';
        break;
      default:
        statusText = order?.status;

    }
    return statusText;
  }

}
