import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, map} from 'rxjs/operators';
import * as fromOnline from 'src/app/ngrx/online/online.selectors'
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { StoresService } from 'src/app/services/stores.service';
import * as fromStores from 'src/app/ngrx/status/stores/stores.selectors'
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import * as fromHubManager from 'src/app/ngrx/status/hub-manager/hub-manager.selectors'
import { StoreTicketService } from 'src/app/services/store-ticket.service';
import { ErrandsService } from 'src/app/services/errands.service';
import { OtherService } from 'src/app/services/other.service';

@Component({
    selector: 'app-stores-table',
    templateUrl: './stores-table.component.html',
    styleUrls: ['./stores-table.component.scss'],
    standalone: false
})
export class StoresTableComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Output() onlineStoresCountEvent = new EventEmitter<boolean>();

  stores: any = [];
  //currentHubStatus: any = [];
  currentStores: any = [];

  additionalPhonesModal:boolean = false;
  pauseStoreModal:boolean = false;
  storeTicket: boolean = false;
  requestDeliveryManModal: boolean = false;

  selectedStore: any;

  currentUserLocation: any = <any>{ }
  phoneCallRegex = /^213/i;

  isChecked: boolean = true;

  initDate = new Date()

  constructor(
    private storesService: StoresService,
    private store: Store,
    public sanitization: DomSanitizer,
    private messageService: MessageService,
    private storeTicketService: StoreTicketService,
    private errandsService: ErrandsService,
    private otherService: OtherService
  ) { }

  ngOnInit(): void {

    this.store.pipe(select(fromStores.getCurrentStores), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentStores = data;
        this.stores = Object.values(data || []).filter((x: any) => x)
        this.store.pipe(select(fromOnline.getCurrentOnline), takeUntil(this.destroy$)).subscribe(
          (data: any) => {
            this.stores = this.stores?.map(
              (element: any) => {
                let Nelement = {... element}
                Nelement.online = Object.keys(data || {}).includes(element?.id?.toString())
                return Nelement;
              }
            )
            this.stores?.sort((a: any) => (a?.online)? -1 : 0)
            this.onlineStoresCountEvent.emit(this.stores?.filter((a: any) => (a?.online)).length);
          }
        )
        this.store.pipe(select(fromHubManager.getCurrentHubManager), takeUntil(this.destroy$)).subscribe(
          (data: any) => {
            this.stores = this.stores?.map(
              (element: any) => {
                let Nelement = {... element}
                let temp_stores_list = data?.open_stores?.split(',') || [];
                Nelement.isOpen = (temp_stores_list).includes(element?.id?.toString())
                return Nelement;
              }
            )
          }
        )
      }
    )

    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
        this.phoneCallRegex = new RegExp('^' + this.currentUserLocation?.phone_int_prefix, 'i');
      }
    )
  }

  resetTable(table: any){
    table.filterGlobal("", "contains");
    table.clear();
    table.reset();
    this.stores = [... this.stores]
    this.stores?.sort((a: any) => (a?.online)? -1 : 0)
  }

  applyFilterGlobal($event: any, stringVal: string, dt: any) {
    dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  selectedType: any; 
  openAdditionalPhonesModal(store: any){
    this.selectedType = "store";
    this.selectedStore = store
    this.additionalPhonesModal = true;
  }

  additionalPhonesModalEvent(){
    this.additionalPhonesModal = false;
  }

  async sendStoreTicket(form: any){
    if(form?.value.store_ticket){
      /* data = <any>{
        accept_delivery: 0,
        accept_delivery_at: null
      } */

      let store_ticket_data = <any>{
        store_id: this.selectedStore?.id,
        description: form?.value?.reasonValue,
        type: "request"
      }

      if(form?.value.accept_delivery_on){
        store_ticket_data.description += " " + form?.value.accept_delivery_on
      }

      if(form?.value.reasonValue === "Établissement Fermé jusqu`à"){
        store_ticket_data.type = "update_hours"
      }else if((form?.value.reasonValue === "Établissement injoignable") 
                || (form?.value.reasonValue === "Établissement injoignable")){
        store_ticket_data.type = "unreachable"
      }
      
      if(form?.value?.until){
        store_ticket_data.description += " " + form?.value.until
      }

      if(form?.value?.store_ticket_details){
        store_ticket_data.description += " " + form?.value.store_ticket_details
      }

      await this.storeTicketService.createStoreTicket(store_ticket_data)
            .pipe(map(
              (res: any) => {
                this.messageService.clear();
                this.messageService.add({key: 'KeyDashboard', severity:'success', summary:'Succès', detail: "Réclamation envoyée"});
              }
            ))
            .toPromise()
            .catch((err: any)=>{
              this.messageService.clear();
              this.messageService.add({key: 'KeyPauseStore', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)});
            });
    }
  }

  

  async submitPauseStore(btnS: any, form: any){
    btnS.disabled = true;
    let data = <any>{}

    this.sendStoreTicket(form);

    if(form?.value.accept_delivery_on != "autre"){
      data = this.otherService.settingPauseStoreData(form);

      this.updateStore(data, this.selectedStore?.id, btnS);
    }else{
      btnS.disabled = false;
      this.pauseStoreModal = false;
    }
  }

  submitRequestDeliveryMan(btnS: any, form: any){
    btnS.disabled = true;
    const data = {
      store_id: this.selectedStore?.id,
      description: "Commande chez " + this.selectedStore?.name
    }
    this.errandsService.createErrands(data).subscribe(
      (res: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboard', severity:'success', summary:'Succès', detail: "Envoyer avec Succès"});
        btnS.disabled = false;
        this.selectedStore = null;
        this.requestDeliveryManModal = false;
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboard', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
  }

  submitPauseStoreUndetermined(btnS: any){
    btnS.disabled = true;

    let data = <any>{
      accept_delivery: 0,
      accept_delivery_at: null
    }

    this.updateStore(data, this.selectedStore?.id, btnS);
  }

  updateStore(data: any, store_id: number, btnS: any){
    this.storesService.updateStoreById(data, store_id).subscribe(
      (res: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboard', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"});
        btnS.disabled = false;
        this.pauseStoreModal = false;
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboard', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
  }

  submitStartStore(btnS: any, store: any){
    let data = <any>{
      accept_delivery: 1,
      accept_delivery_at: null
    }

    this.updateStore(data, store?.id, btnS);
  }

  changeStoreTicket(event: any){
    this.storeTicket = false;
  }

  openPauseStoreModal(store: any){
    this.selectedStore = store;
    this.initDate = new Date();
    this.initDate.setMinutes(0)
    this.initDate.setHours(11);
    this.pauseStoreModal = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
