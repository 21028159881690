import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ClientsService } from 'src/app/services/clients.service';
import { DeliveriesService } from 'src/app/services/deliveries.service';
import { OtherService } from 'src/app/services/other.service';
import { OrdersService } from 'src/app/services/orders.service';
import { FormArray, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromStores from 'src/app/ngrx/status/stores/stores.selectors'
import * as fromDelivery from 'src/app/ngrx/status/delivery/delivery.selectors'
import { DomSanitizer } from '@angular/platform-browser';
import { ClientTransactionsService } from 'src/app/services/client-transactions.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import { ActivatedRoute, Router } from '@angular/router';
import { ErrandsService } from 'src/app/services/errands.service';
import * as fromHubManager from 'src/app/ngrx/status/hub-manager/hub-manager.selectors'
import { StoreTicketService } from 'src/app/services/store-ticket.service';
import { getCurrentDeliveryPositions } from 'src/app/ngrx/status/delivery-positions/delivery-positions.selectors';
import { firstValueFrom, map } from 'rxjs';
declare var google: any;

@Component({
    selector: 'app-order-side-bar',
    templateUrl: './order-side-bar.component.html',
    styleUrls: ['./order-side-bar.component.scss'],
    standalone: false
})
export class OrderSideBarComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() selectedOrder: any = null;
  @Input() openOrderSidebar: boolean = false;
  @Input() showHistoryPanel: boolean = false;
  @Input() showErrandsPanel: boolean = false;

  @Input() modalMode: boolean = false;
  @Input() fullScreenOrderSidebar: boolean = false;

  @Output() orderSidebarEvent = new EventEmitter<boolean>();
  @Output() historyStoreClickEvent = new EventEmitter<any>();
  
  //deliveryList: any = {}
  //currentHubStatus: any = {};
  currentStores: any = {};
  currentDelivery: any = {}
  options: google.maps.MapOptions = {
    center: {
      lat: 0,
      lng: 0
    }
  };
  //map: google.maps.Map;

  advancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions[] = [];
  advancedMarkerPositions: google.maps.LatLngLiteral[] = [];

  currentUserLocation: any = <any>{ }
  currentHubManager: any = {};
  delivery_positions: any = {};
  
  workingDelivery: any = [];
  additionalPhones: any = [];
  //overlays: any[] = [];
  promos: any = [];
  transactions: any = [];
  responsiveOptions: any = [];
  img_files: any = [];
  audio_files: any = [];

  statusService: any = null;

  selectedType = "";
  statusValue: string = "";
  reasonValue: string = "";
  sendSMSType: string = "sms";
  mapURL: string = ""

  hiddenSuspendClient: boolean = true;
  hiddenGMap: boolean = true;
  additionalPhonesModal: boolean = false;
  changeStatusModal: boolean = false;
  changeStatusDeliveriesModal: boolean = false;
  promosModal: boolean = false;
  loadingPromos: boolean= false;
  transactionsModal: boolean = false;
  loadingTransactions: boolean = false;
  storeTicket: boolean = false;
  changeStatusErrandModal: boolean = false;
  isSendSMS: boolean = false;
  clientTicket: boolean = false;

  @ViewChild('googleMap') googleMap!: google.maps.Map;

  phoneCallRegex = /^213/i;
  toDay = new Date();

  changeDeliveryForm: any
  initChangeDeliveryForm(){
    this.changeDeliveryForm = new FormGroup({
      all_delivery: new FormControl(''),
      staff_id: new FormControl(null, [Validators.required]),
    });
  }

  clientToSuspendForm: any
  initClientToSuspend(){
    this.clientToSuspendForm = new FormGroup({
      comment: new FormControl(this.selectedOrder?.client?.comment, [Validators.required]),
      suspended: new FormControl(this.selectedOrder?.client?.suspended),
    });
    this.hiddenSuspendClient = !(this.selectedOrder?.client?.suspended || this.selectedOrder?.client?.comment != "")
  }

  changeStatusForm: any;
  initChangeStatus(){
    this.changeStatusForm = new FormGroup({
      status: new FormControl('', [Validators.required]),
      reasonValue: new FormControl(''),
      comment: new FormControl(''),
      store_pause: new FormControl(''),
      product_unavailable: new FormArray([]),
      supplement_unavailable: new FormArray([]),
      store_ticket: new FormControl(''),
      store_ticket_details: new FormControl(''),
    })
  }

  constructor(
    public otherService: OtherService,
    public deliveriesService: DeliveriesService,
    public ordersService: OrdersService,
    public sanitization: DomSanitizer,
    private store: Store,
    private clientsService: ClientsService,
    private clientTransactionsService: ClientTransactionsService,
    private messageService: MessageService,
    private firebaseService: FirebaseService,
    private router: Router,
    private route: ActivatedRoute,
    public errandsService: ErrandsService,
    private storeTicketService: StoreTicketService,
  ) {
    /* let map: google.maps.Map;
    async function initMap(): Promise<void> {
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      map = new Map(document.getElementById("map") as HTMLElement, {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
      });
    }

    initMap(); */
   }

  isOrdersService(): boolean { return this.statusService instanceof OrdersService; }
  isDeliveriesService(): boolean { return this.statusService instanceof DeliveriesService; }

  

  ngOnInit(): void {
    console.log("order sidebar")
    console.log((+this.selectedOrder?.order?.store_price == (
      +this.selectedOrder?.order?.price || +this.selectedOrder?.client_price)))
    console.log((+this.selectedOrder?.order?.store_price), (
      +this.selectedOrder?.order?.price) , (+this.selectedOrder?.client_price))
    this.initChangeDeliveryForm();
    this.initClientToSuspend();
    this.initChangeStatus();

    this.store.pipe(select(fromStores.getCurrentStores), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentStores = data;
      }
    )

    this.store.pipe(select(fromDelivery.getCurrentDelivery), takeUntil(this.destroy$)).subscribe(
      (delivery: any) => {
        this.currentDelivery = delivery;
        this.filterWorkingDelivery(delivery);
      }
    )

    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
        this.phoneCallRegex = new RegExp('^' + this.currentUserLocation?.phone_int_prefix, 'i');
      }
    )

    this.store.pipe(select(fromHubManager.getCurrentHubManager), takeUntil(this.destroy$)).subscribe(
      (hubManager: any) => {
        this.currentHubManager = hubManager;
      }
    )

    this.store.pipe(select(getCurrentDeliveryPositions), takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        this.delivery_positions = res;
        this.filterWorkingDelivery();
      }
    )

    this.img_files = [];
    this.audio_files = [];

    if(this.selectedOrder?.errand){

      this.filterFiles()

      this.responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
      ];
    }

  }

  filterFiles(){
    this.img_files = this.selectedOrder?.data?.files?.filter((x: any) => !x.endsWith('.mp4'));
    this.audio_files = this.selectedOrder?.data?.files?.filter((x: any) => x.endsWith('.mp4'));
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if(event){
      if(event.latLng){
        console.log(event.latLng.toJSON());
      }
    }
  }

  initMap(){
    this.advancedMarkerPositions  = [];
    let clientPos = this.selectedOrder?.errand ? this.selectedOrder?.to_location : (this.selectedOrder?.location);
    clientPos = this.otherService.getPosition(clientPos);

    let storePos = this.selectedOrder?.errand ? (this.selectedOrder?.from_location) : (this.currentStores[this.selectedOrder?.order?.store_id]?.location)
    storePos = this.otherService.getPosition(storePos);

    this.options = {
      center: clientPos,
      zoom: 12
    };

    /* this.overlays = [
      new google.maps.Marker({position: clientPos, title: this.selectedOrder?.client?.name, icon: "../../../assets/images/client-icon.png"}),
      new google.maps.Marker({position: storePos, title: this.selectedOrder?.order?.store?.name, icon: "../../../assets/images/store-icon.png"})
    ];
 */
    const clientImg = document.createElement('img');
    clientImg.src = '../../../assets/images/client-icon.png';

    const storeImg = document.createElement('img');
    storeImg.src = '../../../assets/images/store-icon.png';

    this.advancedMarkerOptions = [
      {content: clientImg, title: this.selectedOrder?.client?.name, gmpDraggable: false, },
      {content: storeImg, title: this.selectedOrder?.order?.store?.name, gmpDraggable: false}
    ]

    this.advancedMarkerPositions.push(clientPos);
    this.advancedMarkerPositions.push(storePos);
    

    /* let baseIconURL = "https://hub2-staging.foodbeeper.com/assets/images/";
    this.mapURL = "https://maps.googleapis.com/maps/api/staticmap?center=" +
                  this.options.center.lat +
                  "," + this.options.center.lng +
                  "&markers=icon:" + baseIconURL + "client-icon.png" + "%7C" + this.options.center.lat + "," + this.options.center.lng +
                  "&markers=icon:" + baseIconURL + "store-icon.png" + "%7C" + storePos.lat + "," + storePos.lng +
                  "&style=visibility:on&size=1000x400&key=AIzaSyDXJaJ5oxsNJv2LVB7_UQScC3dq3L7W4Vo" */

    if(this.selectedOrder?.staff){
      let delivery = this.currentDelivery[this.selectedOrder?.staff_id]
      let deliveryPosition = this.delivery_positions[this.selectedOrder?.staff_id]
      let position_date = new Date(deliveryPosition?.created?.date || "").getTime()
      let today = new Date().getTime();

      if(today - position_date < 300000){
        let deliveryPos = this.otherService.getPosition(deliveryPosition?.location);
  
        let data = <any>{
          position: deliveryPos,
          name: this.selectedOrder?.staff?.name
        }

        let icon = "green-pin-admin";
        if (delivery.remaining_deliveries != 0) {
          icon = "orange-pin-admin";
        } else {
          icon = "green-pin-admin";
        }
  
        if (delivery.vehicle == 'moto') {
          if (delivery.remaining_deliveries != 0) { icon = "moto-pin-orange"; } else icon = "moto-pin-green";
        } else if (delivery.vehicle == 'voiture') {
          if (delivery.remaining_deliveries != 0) { icon = "car-pin-orange"; } else icon = "car-pin-green";
        } else if (delivery.vehicle == 'velo') {
          if (delivery.remaining_deliveries != 0) { icon = "bicycle-pin-orange"; } else icon = "bicycle-pin-green";
        }
  
        data.icon = icon;
        
        //this.markerPositions.push(new google.maps.Marker({position: data.position, title: data.name, icon: "../../../assets/images/" + data.icon + ".png"}))
        //this.mapURL += "&markers=icon:" + baseIconURL + data?.icon + ".png" + "%7C" + data?.position?.lat + "," + data?.position?.lng

        const deliveryImg = document.createElement('img');
        deliveryImg.src = "../../../assets/images/" + data.icon + ".png";

        this.advancedMarkerOptions.push({content: deliveryImg, title: data.name, gmpDraggable: false })
        this.advancedMarkerPositions.push(data.position);
      }
    }
  }

  showMap(){
    this.initMap();
    this.hiddenGMap = !this.hiddenGMap;
  }

  dataTable: any; targetId: any; additionalPhonesChanges: any;
  openAdditionalPhonesModal(type: string){
    this.selectedType = type;
    if(type === "store"){
      this.dataTable = (this.currentStores || {})[this.selectedOrder?.order?.store_id]?.data?.additional_phones;
      this.targetId = this.selectedOrder?.order?.store_id;
      this.additionalPhonesChanges = this.currentStores;
    }else if(type === "staff"){
      this.dataTable = (this.currentDelivery || {})[this.selectedOrder?.staff_id]?.data?.additional_phones;
      this.targetId = this.selectedOrder?.staff_id;
      this.additionalPhonesChanges = this.currentDelivery;
    }
    this.additionalPhonesModal = true;
  }

  additionalPhonesModalEvent(){
    this.additionalPhonesModal = false;
  }

  submitChangeDelivery(btnS: any, service: any){
    btnS.disabled = true;
    service.updateDataById({staff_id: this.changeDeliveryForm.get('staff_id').value}, this.selectedOrder.id).subscribe(
      (res: any)=>{
        setTimeout(() => {
          btnS.disabled = false;
        }, 1000);
        this.initChangeDeliveryForm();
        //this.selectedOrder = {... this.orders.filter((x: any) => x.id == this.selectedOrder.id)[0]}
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"});
        this.onSidebarClose()
        this.openOrderSidebar = false;
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
  }

  submitChangeHub(btnS: any, form: NgForm){
    if(form.value.hub_id != ""){
      btnS.disabled = true;
      this.deliveriesService.updateDataById(form.value, this.selectedOrder.id).subscribe(
        (res: any)=>{
          setTimeout(() => {
            btnS.disabled = false;
          }, 1000);
          //this.selectedOrder = {... this.orders.filter((x: any) => x.id == this.selectedOrder.id)[0]}
          this.messageService.clear();
          this.messageService.add({key: 'KeyDashboardSidebar', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"});
        },
        (err: any)=>{
          this.messageService.clear();
          this.messageService.add({key: 'KeyDashboardSidebar', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
          btnS.disabled = false;
        }
      );
    }
  }

  changeOrderStatus(btnS: any, status: string){
    this.statusService = this.ordersService;
    this.statusValue = status;
    if(status == "rejected"){
      this.changeStatusModal = true;
    }else{
      this.submitUpdateStatus(btnS);
    }
  }

  submitUpdateStatus(btnS: any, form: any = null){
    if((this.changeStatusForm.get('status').value == 'rejected' 
        || this.changeStatusForm.get('status').value == 'canceled') 
        && !this.changeStatusForm.get('reasonValue').value
      ){
        if (this.changeStatusForm.get('reasonValue').value == "Autre"
            && !this.changeStatusForm.get('comment').value){
          this.messageService.clear();
          this.messageService.add({key: 'KeyChangeStatus', severity:'error', summary:'Erreur', detail: 'La raison est obligatoire!'});
        }else{
          this.messageService.clear();
          this.messageService.add({key: 'KeyChangeStatus', severity:'error', summary:'Erreur', detail: 'La raison est obligatoire!'});
        }
    } else if ((this.changeStatusForm.get('status').value == 'rejected' 
                || this.changeStatusForm.get('status').value == 'canceled')
                && this.changeStatusForm.get('reasonValue').value == "Autre"
                && !this.changeStatusForm.get('comment').value){
            this.messageService.clear();
            this.messageService.add({key: 'KeyChangeStatus', severity:'error', summary:'Erreur', detail: 'Le commentaire est obligatoire!'});
    }else{
      btnS.disabled = true;

      let id: number;
      if(this.statusService instanceof OrdersService){
        id = this.selectedOrder.order_id
      }else{
        id = this.selectedOrder.id
      }

      let data = <any>{
        status: this.statusValue
      }

      let store_ticket = false;
      if(this.changeStatusForm.get('store_ticket').value == true){
        store_ticket = true;
      }

      let store_ticket_data = <any>{
        store_id: this.selectedOrder.order.store_id,
        description: this.selectedOrder?.order?.order_number,
        type: "request"
      }

      if(form?.value.reasonValue){
        if(form?.value.reasonValue === "Produit non disponible"){
          data.comment = "Non disponible :["+ this.changeStatusForm.get('product_unavailable').value +"]"
        }else if(form?.value.reasonValue === "Supplément non disponible"){
          data.comment = "Supplément non disponible : " + this.changeStatusForm.get('supplement_unavailable').value 
        }else if((form?.value.reasonValue === 'Etablissement fermé') 
            || (form?.value.reasonValue === 'Etablissement n\'a pas répondu') 
            || (form?.value.reasonValue === 'Etablissement en surcharge')
            || (form?.value.reasonValue === 'Problème technique chez établissement')){
          if(this.changeStatusForm.get('store_pause').value === '600'){
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            tomorrow.setHours(8, 0, 0, 0);
            const timeDifference = +tomorrow - +today;
            const minutes = Math.floor(timeDifference / (1000 * 60));

            data.comment = form?.value.reasonValue + ". store_pause:" + minutes
          }else{
            data.comment = form?.value.reasonValue + ". store_pause:" + this.changeStatusForm.get('store_pause').value
          }
        }else if(form?.value.reasonValue === "Fin de service"){
          data.comment = "Fin de service. store_pause:600";
        }else{
          data.comment = form?.value.reasonValue
        }

        //store ticket
        if(form?.value.reasonValue === "Produit non disponible"){
          store_ticket_data.type = "update_menu"
        }else if(form?.value.reasonValue === "Etablissement fermé"){
          store_ticket_data.type = "update_hours"
        }else if(form?.value.reasonValue === "Etablissement n'a pas répondu"){
          store_ticket_data.type = "unreachable"
        }else if(form?.value.reasonValue === "Autre"){
          store_ticket_data.type = "request"
        }
        store_ticket_data.description += " " + form?.value.reasonValue

      }

      if(form?.value.comment){
        data.comment = form?.value.comment

        //store ticket
        store_ticket_data.description += " " + form?.value.comment
      }

      if(form?.value.store_ticket_details){
        store_ticket_data.description += " - " + form?.value.store_ticket_details
      }

      if(form?.value.reason && form?.value.location){
        data.comment = form?.value.reason + ' \n ' + form?.value.location
      }

      this.statusService.updateDataById(data, id).subscribe(
        (res: any)=>{
          if(store_ticket == true){
            this.storeTicketService.createStoreTicket(store_ticket_data).subscribe(
              (res: any)=>{
                this.messageService.clear();
                this.messageService.add({key: 'KeyDashboard', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"})
              },
              (err: any)=>{
                this.messageService.clear();
                this.messageService.add({key: 'KeyDashboard', severity:'error', summary:'Erreur', detail: JSON.stringify(err)})
              }
            )
          }

          btnS.disabled = false;
          //this.openOrderSidebar = false;
          this.messageService.clear();
          this.messageService.add({key: 'KeyDashboard', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"})

          if(data.status == "canceled"){
            this.onSidebarClose()
            this.openOrderSidebar = false;
          }
          this.statusService = null;
          this.statusValue = "";
        },
        (err: any)=>{
          this.messageService.clear();
          this.messageService.add({key: 'KeyDashboard', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
          btnS.disabled = false;
        }
      );
      this.changeStatusModal = false;
      this.changeStatusDeliveriesModal = false;
    }
  }

  submitNoCollect(btnS: any, orderCollectedConfirmation: any, orderCollected: any){
    btnS.disabled = true;
    this.deliveriesService.updateDataById({nocollect: 1}, this.selectedOrder.id).subscribe(
      (res: any)=>{
        setTimeout(() => {
          btnS.disabled = false;
        }, 1000);

        orderCollectedConfirmation.hidden = true; 
        orderCollected.hidden = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"});
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
  }

  submitClientToSuspend(btnS: any){
    btnS.disabled = true;
    this.clientToSuspendForm.value.suspended = +this.clientToSuspendForm.get("suspended").value;
    this.clientsService.updateClientById(this.clientToSuspendForm.value, this.selectedOrder?.client?.id).subscribe(
      (res: any)=>{
        setTimeout(() => {
          btnS.disabled = false;
        }, 1000);

        this.initClientToSuspend();
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"});
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
  }

  async filterWorkingDelivery(delivery: any = this.currentDelivery){
    this.workingDelivery = await this.otherService.globalFilterWorkingDelivery(this.changeDeliveryForm.get('all_delivery').value, delivery, this.delivery_positions, this.selectedOrder);
  }

  onSidebarClose(){
    //this.selectedOrder = null;
    console.log("close")
    this.initClientToSuspend();
    this.hiddenSuspendClient = true;
    this.hiddenGMap = true;
    this.orderSidebarEvent.emit(false);
  }

  onHistoryStoreClick(filter: any){
    this.historyStoreClickEvent.emit(filter)
  }

  openPromosModal(){
    this.promosModal = true;
    this.loadingPromos = true;
    this.clientsService.getPromosByClient(this.selectedOrder?.client?.id).subscribe(
      (res: any)=>{
        this.promos = res.data
        this.loadingPromos = false;
      },
      (err: any)=>{
        this.loadingPromos = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyPromosModal', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    );
  }

  applyPromo(promo: any){
    this.promosModal = true;
    this.loadingPromos = true;
    this.clientsService.applyPromoByClient(this.selectedOrder?.order_id, promo?.id).subscribe(
      (res: any)=>{
        this.loadingPromos = false;
        this.promosModal = false;
      },
      (err: any)=>{
        this.loadingPromos = false;
        this.promosModal = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyPromosModal', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    );
  }

  transactionsCredit: any;
  openTransactionsModal(){
    this.transactionsModal = true;
    this.loadingTransactions = true;
    this.clientTransactionsService.getClientTransactions(this.selectedOrder?.client?.id).subscribe(
      (res: any)=>{
        this.transactions = res.data
        this.transactionsCredit = res?.meta?.credit
        this.loadingTransactions = false;
      },
      (err: any)=>{
        this.loadingTransactions = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyTransactionsModal', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    let data: any = changes?.selectedOrder
    
    if(data?.currentValue?.id !== data?.previousValue?.id){
      this.initChangeDeliveryForm();
      this.initClientToSuspend();
      this.filterWorkingDelivery();
      if(!this.hiddenGMap){
        this.initMap()
      }
      if(this.selectedOrder?.errand){
        this.filterFiles()
      }else{
        this.img_files = [];
        this.audio_files = [];
      }
    }
    if(Object.keys(changes?.selectedOrder?.currentValue || {}).length < 1){
      this.onSidebarClose()
      this.openOrderSidebar = false;
    }
  }

  onCheckboxChangeComment(e: any, form: any, field: any) {
    const product_unavailable: FormArray = form.get(field?.toString()) as FormArray;
    if (e.target.checked) {
      product_unavailable.push(new FormControl(e.target.value));
    } else {
       const index = product_unavailable.controls.findIndex(x => x.value === e.target.value);
       if(index !== -1){
        product_unavailable.removeAt(index);
      }
    }
  }

  onChangeReasonValue(){
    if(this.changeStatusForm.get('reasonValue').value === "Produit non disponible"){      
      this.changeStatusForm.get('product_unavailable').setValidators(Validators.required)
    } else {
      this.changeStatusForm.setControl('product_unavailable', new FormArray([]));
      this.changeStatusForm.get('product_unavailable').clearValidators();
    }
  }

  closeChangeStatusModal(){
    //console.log("in")
    this.statusService = null; 
    this.statusValue = '';
    this.reasonValue = '';
    this.initChangeStatus()
  }

  changeStoreTicket(event: any){
    this.storeTicket = false;
  }

  changeClientTicket(event: any){
    this.clientTicket = false;
  }

  duplicateOrder(){
    //console.log(this.selectedOrder)
    const setting = {
      client: {
        client_id: this.selectedOrder.client.id,
        name: this.selectedOrder?.client?.name,
        phone: this.selectedOrder?.client?.phone,
        address: this.selectedOrder?.address
      },
      delivery_price: this.selectedOrder.price, 
      delivery_location: this.selectedOrder.location,
      store_id: this.selectedOrder.order.store_id,
      items: this.selectedOrder.order.items
    }
    this.router.navigate(
      ['../orders/store', this.selectedOrder?.order?.store_id], 
      { 
        relativeTo: this.route, 
        state: setting, 
        queryParams: { 
          delivery_price: this.selectedOrder.price, 
          delivery_location: this.selectedOrder.location,
          service_fee: this.selectedOrder?.order?.service_fee
        }, 
        skipLocationChange: true 
      }
    )
  }

  submitUpdateErrandStatus(btnS: any, form: any = null){
    btnS.disabled = true;
    const data = <any>{
      status: form?.value.status,
      status_comment: form?.value.comment || form?.value.status_comment,
    }
    this.errandsService.updateDataById(data, this.selectedOrder.id).subscribe(
      (res: any)=>{
        btnS.disabled = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboard', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"})
        if(form?.value.status == "canceled"){
          this.onSidebarClose()
          this.openOrderSidebar = false;
        }
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboard', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
    this.changeStatusErrandModal = false;
  }

  closeSendSMSEvent(event: any){
    this.isSendSMS = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
