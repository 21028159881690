import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromActiveDeliveries from 'src/app/ngrx/active-deliveries/active-deliveries.selectors'
import * as fromDelivery from 'src/app/ngrx/status/delivery/delivery.selectors'
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import * as fromActiveErrands from 'src/app/ngrx/active-errands/active-errands.selectors'

@Component({
    selector: 'app-deliveries-table',
    templateUrl: './deliveries-table.component.html',
    styleUrls: ['./deliveries-table.component.scss'],
    standalone: false
})
export class DeliveriesTableComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  deliveries: any = [];
  //currentHubStatus: any = [];
  currentDelivery: any = [];
  activeDeliveries: any = [];
  currentActiveErrands: any = [];

  additionalPhonesModal: boolean = false;

  selectedDelivery: any;

  currentUserLocation: any = <any>{ }
  phoneCallRegex = /^213/i;

  constructor(
    private store: Store,
    public sanitization: DomSanitizer,
  ) { }

  ngOnInit(): void {

    this.store.pipe(select(fromDelivery.getCurrentDelivery), takeUntil(this.destroy$)).subscribe(
      (delivery: any) => {
        this.currentDelivery = delivery;
        this.deliveries = Object.entries(delivery || []).filter((x: any) => x[1]?.working == 1)

        this.store.pipe(select(fromActiveDeliveries.getCurrentActiveDeliveries), takeUntil(this.destroy$)).subscribe(
          (data: any) => {
            this.activeDeliveries = {... data};
            this.deliveries.map(
              (x: any) =>{
                let pendingOrders: any = [];
                Object.values(this.activeDeliveries[x[0]] || []).forEach((element: any) => {
                  pendingOrders.push(element?.order?.order_number?.split('-')[0])
                });

                let y : any = {... x[1]}
                y.pendingOrdersLength = pendingOrders.length
                y.pendingOrdersToText = pendingOrders.join('\n')
                x[1] = y
                return x
              }
            )

            this.defaultSorting()
          }
        )

        this.store.pipe(select(fromActiveErrands.getCurrentActiveErrands), takeUntil(this.destroy$)).subscribe(
          (data: any) => {
            this.currentActiveErrands = {... data};

            this.deliveries.map(
              (x: any) =>{
                let pendingErrands: any = [];

                Object.values(this.currentActiveErrands[x[0]] || []).forEach((element: any) => {
                  pendingErrands.push('-')
                });

                let y : any = {... x[1]}
                y.pendingErrandsLength = pendingErrands.length
                y.pendingErrandsToText = pendingErrands.join('\n')
                x[1] = y
                return x
              }
            )

            this.defaultSorting()
          }
        )
    
      }
    )

    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
        this.phoneCallRegex = new RegExp('^' + this.currentUserLocation?.phone_int_prefix, 'i');
      }
    )
  }

  resetTable(table: any){
    table.filterGlobal("", "contains");
    table.clear();
    table.reset();
    this.deliveries = [... this.deliveries]
    //this.deliveries?.sort((a: any) => (a[1]?.online)? -1 : 0)

    this.defaultSorting()
  }

  applyFilterGlobal($event: any, stringVal: string, dt: any) {
    dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  selectedType: any; 
  openAdditionalPhonesModal(delivery: any){
    this.selectedType = 'staff';
    this.selectedDelivery = delivery
    this.additionalPhonesModal = true;
  }

  additionalPhonesModalEvent(){
    this.additionalPhonesModal = false;
  }

  pendingOrders: any = [];
  pendingOrdersByStaff(staff_id: number){
    this.pendingOrders = [];
    Object.values(this.activeDeliveries[staff_id] || []).forEach((element: any) => {
      this.pendingOrders.push(element?.order?.order_number)
    });
  }

  defaultSorting(){
    /* this.deliveries.sort(
      (a: any, b: any) => {
        if(a[1]?.online && !b[1]?.online){
          return -1
        }if(b[1]?.online && !a[1]?.online){
          return 1
        }

        return +(a[1]?.pendingOrdersLength) - +(b[1]?.pendingOrdersLength)
      }
    ); */

    this.deliveries.sort(
      (a: any, b: any) => {
        let temp = 0

        if(a[1]?.online && !b[1]?.online){
          temp = -1
        }else if(b[1]?.online && !a[1]?.online){
          temp = 1
        }

        if(!b[1]?.online && !a[1]?.online ){
          temp = (+(b[1]?.pendingOrdersLength) - +(a[1]?.pendingOrdersLength))
        }

        if(temp === 0 ){
          temp = (+(a[1]?.pendingOrdersLength) - +(b[1]?.pendingOrdersLength))
        }

        return temp
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
