<p-table #historyErrandsTable [value]="orders" 
        responsiveLayout="scroll" 
        [scrollable]="true" 
        scrollHeight="calc(100vh - 280px)"
        [loading]="loading">
    <ng-template pTemplate="caption">
        <form [formGroup]="filterHistoryErrandsForm" (ngSubmit)="submitFilterHistoryErrands(btnSFHO)">
            <div class="row">
                <div class="col-12 col-xxl-2 d-flex">
                    <!-- <input class="form-control" type="text" formControlName="order_number" placeholder="N° de commande"> -->
                
                    <select class="form-select" formControlName="status">
                        <option value="">Statut</option>
                        <option value="completed">Terminée</option>
                        <option value="canceled">Annulée</option>
                    </select>
                </div>
                <div class="col-12 col-xxl-5  d-flex align-items-center">
                    <input class="form-control" type="date" formControlName="start_date">
                    &nbsp; <span>Au:</span> &nbsp;
                    <input class="form-control" type="date" formControlName="end_date">
                </div>
                <div class="col-12 col-xxl-5  d-flex align-items-center justify-content-end">
                    <button type="reset" class="btn btn-default-primeng-green mx-1" (click)="resetFilter(historyErrandsTable)">Réinitialiser</button>
                    <button #btnSFHO type="submit" class="btn btn-success-fbeeper">Filtrer</button>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="min-width: 10%" class="text-center" pSortableColumn="status">
                <div class="p-d-flex p-jc-between p-ai-center">
                    Statut
                    <p-sortIcon field="status"></p-sortIcon>
                </div>
            </th>
            <th style="min-width: 5%"></th>
            <th style="min-width: 20%" class="text-start" pSortableColumn="order.order_number">
                <div class="p-d-flex p-jc-between p-ai-center">
                    Client
                    <p-sortIcon field="order.order_number"></p-sortIcon>
                </div>
            </th>
            <th style="min-width: 30%" class="text-start" pSortableColumn="order.store.name">
                <div class="p-d-flex p-jc-between p-ai-center">
                    Source
                    <p-sortIcon field="order.store.name"></p-sortIcon>
                </div>
            </th>
            <th style="min-width: 15%" class="text-start text-md-center">Prix</th>
            <th style="min-width: 10%" class="text-start text-md-center">Créer</th>
            <th style="min-width: 10%" class="text-start text-md-center">Livré</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order>
        <tr style="cursor: pointer;"
            (click)="selectOrder(order)"
            [ngClass]="{'selectedOrder': selectedOrder?.id == order?.id}">
            <td style="min-width: 20%" class="text-center">
                <span class="p-1 rounded-pill" 
                        [ngClass]="{
                            'bgSuccess':(order.status == 'completed'),
                            'bgDanger':(order.status == 'canceled')
                        }">
                    {{order | errandStatus }}
                </span>
            </td>
            <td style="min-width: 5%; text-align: center">
                <img *ngIf="order?.gold" src="assets/images/gold.svg" alt="Gold" width="20" height="20">
            </td>
            <td style="min-width: 10%">
                <div class="d-flex align-items-center">
                    {{order?.client?.name}}
                </div>
                <p-rating *ngIf="order?.rating" [ngModel]="order?.rating?.rating" [readonly]="true" [stars]="5"></p-rating>
            </td>
            <td style="min-width: 30%">
                
            </td>
            <td style="min-width: 15%" class="text-center">
                {{order?.price | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
            </td>
            <td style="min-width: 10%" class="text-center">
                {{order?.created?.date | date: "dd/MM/yyyy"}}
            </td>
            <td style="min-width: 10%" class="text-center">
                {{order?.completed_at?.date | date: "dd/MM/yyyy"}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6" class="text-center">Aucune commande trouvé.</td>
        </tr>
    </ng-template>
</p-table>
<div class="row">
    <div class="col-12">
        <p-paginator [rows]="per_page" [totalRecords]="total" [first]="first" (onPageChange)="onPageChange($event)"></p-paginator>
    </div>
</div>