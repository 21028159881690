import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './online.reducer'

const getOnlineFeatureState = createFeatureSelector<State>('online')

export const getCurrentOnline = createSelector(
    getOnlineFeatureState,
    (state: any) => state.online
)

export const getCurrentError = createSelector(
    getOnlineFeatureState,
    (state: any) => state.error
)