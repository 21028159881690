import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './delivery-positions.reducer'

export const selectFeature = createFeatureSelector<State>('deliveryPositions')

export const getCurrentDeliveryPositions = createSelector(
    selectFeature,
    (state: any) => state.deliveryPosition
);

export const getCurrentError = createSelector(
    selectFeature,
    (state: any) => state.error
);