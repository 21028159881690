import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderStatus' })
export class OrderStatusPipe implements PipeTransform {

  transform(order: any): any {
    let statusText = order?.status || order
    switch (order?.status || order) {
      case 'accepted':
        statusText = "En attente livreur";
        break;
      case "delivery_accepted":
        statusText = 'Acceptée';
        break;
      case "pickup":
        statusText = "En livraison";
        break;
      case "delivered":
        statusText = "Livrée";
        break;
      case 'pending':
        if (order?.order_status === "confirmed") {
          statusText = "En attente";
          break;
        } else if(order?.order_status === "waiting"){
          statusText = "Planifiée";
          break;
        } else {
          statusText = "À Confirmer";
        }
        break;
      default:
        statusText = order?.status  || order;

    }
    return statusText;
  }

}
