<!-- Update working hours modal -->
<p-dialog header="Modifier les heures de travaille de {{selectedStaff?.name}}" [(visible)]="updateWorkingHoursModal" [modal]="true" [style]="{width: '100rem'}"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true" (onHide)="closeModal(); selectedStaff = null;">
    <p-toast key="keyStaffModal"></p-toast>
    <p-progressBar class="mb-1" mode="indeterminate" *ngIf="loader"></p-progressBar>
    <div class="row my-2">
        <div class="col-6">
            <button class="btn "[ngClass]="special_date ? 'btn-info' : 'btn-default-info'" (click)="special_date = !special_date; initUpdateSpecialDateForm()">Date spécial</button>
        </div>
        <div class="offset-3 col-3">
            <app-search-staff *ngIf="!special_date" (selectedStaffEvent)="selectedStaffEvent($event)"></app-search-staff>
        </div>
    </div>
    <form *ngIf="special_date" [formGroup]="updateSpecialDateForm" (ngSubmit)="updateSpecialDate(btnSUSD)">
        <div class="row mt-4">
            <div class="col-2">
                <input type="date" class="form-control" formControlName="date">
            </div>
            <div class="offset-7 col-3 d-flex align-items-center justify-content-end">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="workDay" formControlName="workDay" checked>
                    <label class="form-check-label" for="workDay">Jour de travaille</label>
                </div>
            </div>
        </div>
        <div class="row mt-2" *ngIf="updateSpecialDateForm.get('workDay').value">
            <div class="col-12">
                <table class="w-100" style="user-select: none;">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-center" *ngFor="let hour of th_Hours let i=index">{{hour}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{{(updateSpecialDateForm.get('date').value | date: "dd-MM" )|| "Aucune date"}}</th>
                            <td *ngFor="let hour of th_Hours let i=index" class="border-1 p-0" style="column-count: 2; column-gap: 0px; border-color: white;">
                                <div class="form-check p-0 mb-0">
                                    <input class="form-check-input" type="checkbox" [value]="hour.split(':')[0]+':00'" id="special-date-{{i}}-first" #SDfirst hidden 
                                        (change)="onCheckboxChange($event, updateSpecialDateForm, 'hours')">
                                    <label class="form-check-label" for="special-date-{{i}}-first"
                                        (mouseenter)="upMouseEnter($event, SDfirst)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" class="bi bi-square-fill" viewBox="0 0 16 16"
                                            [ngStyle]="{'fill': SDfirst.checked ? 'var(--primeng-hub-green)' : 'var(--grey-primeng)'}">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"/>
                                        </svg>
                                    </label>
                                </div>
                                <div class="form-check p-0 mb-0">
                                    <input class="form-check-input" type="checkbox" [value]="hour.split(':')[0]+':30'" id="special-date-{{i}}-last" #SDlast hidden 
                                        (change)="onCheckboxChange($event, updateSpecialDateForm, 'hours')">
                                    <label class="form-check-label" for="special-date-{{i}}-last"
                                        (mouseenter)="upMouseEnter($event, SDlast)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" class="bi bi-square-fill" viewBox="0 0 16 16"
                                            [ngStyle]="{'fill': SDlast.checked ? 'var(--primeng-hub-green)' : 'var(--grey-primeng)'}">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"/>
                                        </svg>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn fw-bold me-3" style="color: var(--gray-hub-fbeeper);" (click)="updateWorkingHoursModal = false;">Annuler</button>
                <button type="submit" class="btn btn-success-fbeeper fw-bold" style="background-color: var(--success-hub-fbeeper);" #btnSUSD [disabled]="updateSpecialDateForm.invalid"> 
                    <div *ngIf="btnSUSD.disabled && updateSpecialDateForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Sauvgarder
                </button>
            </div>
        </div>
    </form>

    <form *ngIf="!special_date" [formGroup]="updateWorkingHoursForm" (ngSubmit)="updateWorkingHours(btnSUWH)">
        <div class="row mt-2">
            <div class="col-12">
                <table class="w-100" style="user-select: none;">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-start" *ngFor="let hour of th_Hours let i=index">{{hour}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let control of updateWorkingHoursForm.controls | keyvalue let i=index" formArrayName="{{control.key}}"
                        >
                            <th>{{(control.key) | weekdays}}</th>
                            <td *ngFor="let hour of th_Hours let i=index" class="border-1 p-0" style="column-count: 2; column-gap: 0px; border-color: white;">
                                <div class="form-check p-0 mb-0">
                                    <input class="form-check-input" type="checkbox" [value]="hour.split(':')[0]+':00'" id="{{control.key}}-{{i}}-first" #first hidden 
                                        (change)="onCheckboxChange($event, updateWorkingHoursForm, control.key)" [checked]="updateWorkingHoursForm.get(control.key).value.indexOf(hour.split(':')[0]+':00') != -1">
                                    <label class="form-check-label" for="{{control.key}}-{{i}}-first"
                                        (mouseenter)="upMouseEnter($event, first)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" class="bi bi-square-fill" viewBox="0 0 16 16"
                                            [ngStyle]="{'fill': first.checked ? 'var(--primeng-hub-green)' : 'var(--grey-primeng)'}">
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"/>
                                        </svg>
                                    </label>
                                </div>
                                <div class="form-check p-0 mb-0">
                                    <input class="form-check-input" type="checkbox" [value]="hour.split(':')[0]+':30'" id="{{control.key}}-{{i}}-last" #last hidden 
                                        (change)="onCheckboxChange($event, updateWorkingHoursForm, control.key)" [checked]="updateWorkingHoursForm.get(control.key).value.indexOf(hour.split(':')[0]+':30') != -1">
                                    <label class="form-check-label" for="{{control.key}}-{{i}}-last"
                                        (mouseenter)="upMouseEnter($event, last)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" class="bi bi-square-fill" viewBox="0 0 16 16"
                                            [ngStyle]="{'fill': last.checked ? 'var(--primeng-hub-green)' : 'var(--grey-primeng)'}">>
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"/>
                                        </svg>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 text-end">
                <p class="mb-0">Totales heures: <span class="text-danger">{{totalHours}} h</span></p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn fw-bold me-3" style="color: var(--gray-hub-fbeeper);" (click)="updateWorkingHoursModal = false;">Annuler</button>
                <button type="submit" class="btn btn-success-fbeeper fw-bold" style="background-color: var(--success-hub-fbeeper);" #btnSUWH [disabled]="updateWorkingHoursForm.invalid"> 
                    <div *ngIf="btnSUWH.disabled && updateWorkingHoursForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Sauvgarder
                </button>
            </div>
        </div>
    </form>
</p-dialog>