import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { OtherService } from 'src/app/services/other.service';
import { StaffService } from 'src/app/services/staff.service';
import { StoresService } from 'src/app/services/stores.service';

@Component({
    selector: 'app-additional-phones',
    templateUrl: './additional-phones.component.html',
    styleUrls: ['./additional-phones.component.scss'],
    standalone: false
})
export class AdditionalPhonesComponent implements OnInit {

  service: any;
  @Input() dataTable: any = [];
  @Input() targetId: number = 0;
  @Input() additionalPhonesChanges: any;
  //@Input() selectedOrder: any;
  @Input() type: any;
  @Input() additionalPhonesModal: boolean = false;
  @Output() additionalPhonesModalEvent = new EventEmitter<boolean>();

  loading: boolean = false;

  additionalPhonesForm: any
  initAdditionalPhonesForm(){
    this.additionalPhonesForm = new FormGroup({
      label: new FormControl('', [Validators.required]),
      phone: new FormControl('',[Validators.required, Validators.pattern(/(0)(5|6|7)[0-9]{8}$/)]),
      note: new FormControl(''),
    });
  }
  
  constructor(
    public otherService: OtherService,
    private storeService: StoresService,
    private staffService: StaffService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.fillData()
    this.initAdditionalPhonesForm();
  }

  fillData(){
    if(this.type === "store"){
      this.service = this.storeService;
      /* if(this.selectedOrder){
        this.dataTable = (this.currentHubStatus?.stores || {})[this.selectedOrder?.order?.store_id]?.data?.additional_phones;
        this.targetId = this.selectedOrder?.order?.store_id;
      } */
    }else if(this.type === "staff"){
      this.service = this.staffService;
      /* if(this.selectedOrder){
        this.dataTable = (this.currentHubStatus?.delivery || {})[this.selectedOrder?.staff_id]?.data?.additional_phones;
        this.targetId = this.selectedOrder?.staff_id;
      } */
    }
  }

  deleteAdditionalPhone(index:any, btnS: any){
    let data = [... (this.dataTable || [])]
    data.splice(index, 1)
    this.updateAdditionalPhones(btnS, data);
  }

  updateAdditionalPhones(btnS: any, data: any){
    btnS.disabled = true;
    this.loading = true;
    this.service.updateDataById({key: "additional_phones", value: data}, this.targetId).subscribe(
      (res: any)=>{
        btnS.disabled = false;
        this.initAdditionalPhonesForm();
        this.loading = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyAdditionalPhones', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"});
        this.messageService.add({key: 'KeyHub', severity:'success', summary:'Succès', detail: "Mise à jour avec Succès"});
        this.closeModal()
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyAdditionalPhones', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
  }

  submitAdditionalPhones(btnS: any){
    btnS.disabled = true;
    if(this.additionalPhonesForm.valid) {
      let data = [... (this.dataTable || []), this.additionalPhonesForm.value];
      this.updateAdditionalPhones(btnS, data);
    }else{
      Object.keys(this.additionalPhonesForm.controls).forEach(field => {
        const control = this.additionalPhonesForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      btnS.disabled = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    /* let data: any = changes?.additionalPhonesChanges.currentValue;
    //console.log(data)
    this.loading = false;
    if(this.type === "store"){
      this.dataTable = (data || {})[this.targetId]?.data?.additional_phones;
    }else if(this.type === "staff"){
      this.dataTable = (data || {})[this.targetId]?.data?.additional_phones;
    } */
    /* this.dataTable = changes.dataTable.currentValue; */
  }

  closeModal() {
    this.additionalPhonesModal = false;
    this.additionalPhonesModalEvent.emit(false);
  }

  ngOnDestroy() {
  this.service = null;
  this.dataTable = [];
  this.targetId = 0;
  this.additionalPhonesChanges = null;
  //this.selectedOrder = null;
  this.type  = null;
  this.additionalPhonesModal = false;
  }

}
