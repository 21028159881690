<!-- add phones modal -->
<p-dialog header="Tels supplémentaires" [(visible)]="additionalPhonesModal" 
        [modal]="true" [style]="{width: '35vw'}" [baseZIndex]="10000" 
        [draggable]="false" [resizable]="false" [closable]="true" 
        (onHide)="closeModal()">
    <p-toast key="KeyAdditionalPhones" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
    <p-table [value]="dataTable | keyvalue:otherService.onCompare" [loading]="loading">
        <ng-template pTemplate="header">
            <tr>
                <th>Nom</th>
                <th>Téléphone</th>
                <th>Note</th>
                <th class="text-center">Opération</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-phone let-rowIndex="rowIndex">
            <tr>
                <td>{{phone?.value?.label}}</td>
                <td>{{phone?.value?.phone}}</td>
                <td>{{phone?.value?.note}}</td>
                <td class="text-center">
                    <span #selectDeleteAdditionalPhones>
                        <svg    xmlns="http://www.w3.org/2000/svg" 
                                width="16" 
                                height="16" 
                                fill="var(--danger-hub-fbeeper)" 
                                class="bi bi-trash3 me-2" 
                                viewBox="0 0 16 16"
                                style="cursor: pointer;"
                                (click)="selectDeleteAdditionalPhones.hidden = true; deleteAdditionalPhones.hidden = false;">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                        </svg>
                    </span>
                    <div  #deleteAdditionalPhones hidden="true">
                        <span>êtes-vous sûr de vouloir supprimer ce numéro ?</span><br>
                        <button (click)="selectDeleteAdditionalPhones.hidden = false; deleteAdditionalPhones.hidden = true;" 
                                type="button" 
                                class="btn" 
                                style="color: var(--info-hub-fbeeper);">
                                Non
                        </button>
                        <button #btnDAP
                                (click)="deleteAdditionalPhone(rowIndex, btnDAP)" 
                                type="button" 
                                class="btn" 
                                style="color: var(--danger-hub-fbeeper);">
                                <div *ngIf="btnDAP.disabled" 
                                    class="spinner-border" 
                                    style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                                    role="status">
                                </div>
                                Oui
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3" class="text-center">Aucun numéro de téléphone.</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <form [formGroup]="additionalPhonesForm" (ngSubmit)="submitAdditionalPhones(btnSAP)">
                <div class="row">
                    <div class="col-3 pe-1">
                        <input type="text" class="form-control" placeholder="Nom" formControlName="label">
                        <small class="form-text text-danger" *ngIf="additionalPhonesForm.controls.label.invalid && additionalPhonesForm.controls.label.touched && additionalPhonesForm.controls.label.errors['required']">Le téléphone est obligatoire</small>
                    </div>
                    <div class="col-3 px-0">
                        <input type="text" class="form-control" maxlength="10" placeholder="Téléphone" formControlName="phone">
                        <small class="form-text text-danger" *ngIf="additionalPhonesForm.controls.phone.invalid && additionalPhonesForm.controls.phone.touched && additionalPhonesForm.controls.phone.errors['required']">Le téléphone est obligatoire</small>
                        <small class="form-text text-danger" *ngIf="additionalPhonesForm.get('phone').invalid && (additionalPhonesForm.get('phone').dirty || additionalPhonesForm.get('phone').touched) && additionalPhonesForm.get('phone').errors['pattern']">Invalid</small>
                    </div>
                    <div class="col-4 ps-1">
                        <input type="text" class="form-control" minlength="1" placeholder="Note" formControlName="note">
                    </div>
                    <div class="col-2 text-end">
                        <button #btnSAP type="submit" class="btn btn-default-primeng-green" [disabled]="additionalPhonesForm.invalid">
                            <div *ngIf="btnSAP.disabled && additionalPhonesForm.valid" 
                                class="spinner-border" 
                                style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                                role="status">
                            </div>
                            +Ajouter
                        </button>
                    </div>
                </div>
            </form>
        </ng-template>
    </p-table>
</p-dialog>