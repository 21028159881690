import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OtherService } from 'src/app/services/other.service';
import { select, Store } from '@ngrx/store';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-dropdown-additional-phones',
    templateUrl: './dropdown-additional-phones.component.html',
    styleUrls: ['./dropdown-additional-phones.component.scss'],
    standalone: false
})
export class DropdownAdditionalPhonesComponent implements OnInit {

  @Input() phone: any;
  @Input() phonesList: any = [];

  @Output() openAdditionalPhonesModalEvent = new EventEmitter<string>();

  destroy$: Subject<boolean> = new Subject<boolean>();
  currentUserLocation: any = <any>{ }
  phoneCallRegex = /^213/i;

  constructor(
    private store: Store,
    public otherService: OtherService,
    public sanitization: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
        this.phoneCallRegex = new RegExp('^' + this.currentUserLocation?.phone_int_prefix, 'i');
      }
    )
  }

  openAdditionalPhonesModal(){
    this.openAdditionalPhonesModalEvent.emit();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
