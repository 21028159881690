import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class ClientTransactionsService {

  constructor(private httpserviceService: HttpserviceService) { }

  getClientTransactions(client_id: number){
    return this.httpserviceService.get("client_transactions/" + client_id);
  }
}
