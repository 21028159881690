import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-working-hours-table',
    templateUrl: './working-hours-table.component.html',
    styleUrls: ['./working-hours-table.component.scss'],
    standalone: false
})
export class WorkingHoursTableComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() updateWorkingHoursModalEvent = new EventEmitter<boolean>();
  
  @Input() updateWorkingHoursModal: boolean = false;
  @Input() selectedStaff: any = null;
  @Input() service: any = null;

  loader: boolean = false;
  special_date: boolean = false;
  defaultWorkingHours = ["0:00","0:30","1:00","1:30","2:00","2:30","3:00","3:30","4:00","4:30","5:00","5:30","6:00","6:30","7:00","7:30","8:00","8:30","9:00","9:30","10:00","10:30","11:00","11:30","12:00","12:30","13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"]
  th_Hours = ["0:00","1:00","2:00","3:00","4:00","5:00","6:00","7:00","8:00","9:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"]
  totalHours: number = 0;

  updateWorkingHoursForm: any;
  initUpdateWorkingHoursForm(){
    this.updateWorkingHoursForm = new FormGroup({
      0: new FormArray([]),
      1: new FormArray([]),
      2: new FormArray([]),
      3: new FormArray([]),
      4: new FormArray([]),
      5: new FormArray([]),
      6: new FormArray([]),
    })
  }

  updateSpecialDateForm: any;
  initUpdateSpecialDateForm(){
    let today = new Date()
    this.updateSpecialDateForm = new FormGroup({
      workDay: new FormControl(true),
      date: new FormControl(today.toJSON().split('T')[0], [Validators.required]),
      hours: new FormArray([])
    })
  }

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.initUpdateWorkingHoursForm();
    this.getWorkingHours();
    this.initUpdateSpecialDateForm();
  }

  getWorkingHours(selectedStaff: any = this.selectedStaff){
    this.loader = true;
    this.service.getWorkingHours(selectedStaff?.id).pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        //this.workingHours = res?.data;
        this.patchValues(res?.data);
        this.loader = false;
      },
      (err: any) => {
        this.messageService.clear();
        this.messageService.add({key: 'KeyHub', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    );
  }

  patchValues(workingHours: any){
    workingHours.forEach((element: any) => {
      if(element?.date == null){
        let defaultHoursList = (element?.hours)?.split(";");
        defaultHoursList = defaultHoursList.filter((item: any) => item != '' )
  
        let hour: FormArray = this.updateWorkingHoursForm.get(element?.day?.toString()) as FormArray;
        while (hour.length !== 0) {
          hour.removeAt(0)
        }
        if(defaultHoursList){
          defaultHoursList.forEach((item: any) => {
            hour.push(new FormControl(item));
          });
        }
      }
    });
    this.totalHours = 0;
    Object.values(this.updateWorkingHoursForm.value || {}).forEach((value: any) => {
      this.totalHours += value.length;
    })
    //console.log(this.updateWorkingHoursForm.value)
    this.totalHours = (this.totalHours)/2;
  }

  submitUpdateWorkingHours(btnS: any, data: any){
    this.service.updateWorkingHours(data, this.selectedStaff?.id).subscribe(
      (res: any) => {
        btnS.disabled = false;
        this.getWorkingHours();
        this.messageService.clear();
        this.messageService.add({key: 'keyStaffModal', severity:'success', summary:'Succès', detail: "Personnel mis à jours"});
      },
      (err: any) => {
        btnS.disabled = false;
        this.messageService.clear();
        this.messageService.add({key: 'keyStaffModal', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    );
  }

  updateWorkingHours(btnS: any){
    btnS.disabled = true;
    Object.entries(this.updateWorkingHoursForm.value).forEach(([key, value]: [key: any, value: any]) => {
      var data = <any>{
        day: +key
      };

      //data.hours = value.sort((a: any, b: any) =>(Number(a.split(':')[0]+'.'+a.split(':')[1]) < Number(b.split(':')[0]+'.'+b.split(':')[1])) ? -1 : 1).join(";");
      data.hours = value.sort((a: any, b: any) =>(Number(a.replace(/:/gi, '.')) < Number(b.replace(/:/gi, '.')) ? -1 : 1)).join(";");
      this.submitUpdateWorkingHours(btnS, data);
    });
  }

  updateSpecialDate(btnS: any){
    btnS.disabled = true;
    var data = <any>{
      date: this.updateSpecialDateForm.get('date').value
    };

    if(this.updateSpecialDateForm.get('workDay').value){
      data.hours = this.updateSpecialDateForm.get('hours').value.sort((a: any, b: any) =>(Number(a.replace(/:/gi, '.')) < Number(b.replace(/:/gi, '.')) ? -1 : 1)).join(";");
    }else{
      data.hours = "";
    }

    this.submitUpdateWorkingHours(btnS, data);
  }

  closeModal() {
    this.initUpdateWorkingHoursForm()
    this.initUpdateSpecialDateForm()
    this.updateWorkingHoursModalEvent.emit(false);
  }

  onCheckboxChange(e: any, form: any, field: any) {
    const workingHours: FormArray = form.get(field?.toString()) as FormArray;
    if (e.target.checked) {
      workingHours.push(new FormControl(e.target.value));
      this.totalHours += 0.5;
    } else {
      const index = workingHours.controls.findIndex(x => x.value === e.target.value);
      if(index !== -1){
        workingHours.removeAt(index);
        this.totalHours -= 0.5;
      }
    }
  }

  upMouseEnter(e: any, el: any) {
    if (!this.upIsMouseDown(e)) return;
    el.click()
  }

  upIsMouseDown(e: any) {
    return e.buttons === undefined
      ? e.which === 1
      : e.buttons === 1;
  }

  selectedStaffEvent(event: any){
    let selectedStaff = event;

    this.getWorkingHours(selectedStaff)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
