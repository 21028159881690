<p-dialog header="Envoyer {{(type == 'sms') ? 'un SMS' : ' une Notification'}} au client" [(visible)]="isSendSMS" [modal]="true" styleClass="dialog35"
    [baseZIndex]="10000" [draggable]="true" [resizable]="false" [closable]="true" (onHide)="closeModal()">
    <p-toast key="SendSMSToast"></p-toast>
    <form [formGroup]="sendSMSForm" (ngSubmit)="submitSendSMS(btnSSMS)">
        <div class="row mt-2" *ngIf="type == 'sms'">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Numéro de téléphone</label>
                <input type="text" class="form-control" formControlName="phone" >
                <small class="form-text text-danger"
                        *ngIf="sendSMSForm.controls.phone?.status == 'INVALID' && sendSMSForm.controls.phone.touched">
                        Numéro de téléphone obligatoire!
                </small>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Message <span class="text-danger">*</span></label>
                <select formControlName="message_select" id="sendSMSForm-message" class="form-select" ngModel (change)="OnSelectMessage()">
                    <option value="" selected>Choisir un SMS text </option>
                    <option *ngFor="let message of listMessages"
                            [value]="message">{{message}}</option>
                </select>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <textarea placeholder="Message" type="text" rows="3" class="form-control" formControlName="message" >
                </textarea>
                <small class="form-text text-danger"
                        *ngIf="sendSMSForm.controls.message?.status == 'INVALID' && sendSMSForm.controls.message.touched && sendSMSForm?.controls?.message?.errors?.required">
                        Message obligatoire!
                </small>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-end">
                <button type="button" class="btn btn-default-fbeeper me-3" (click)="closeModal()">Annuler</button>
                <button #btnSSMS type="submit" class="btn btn-success-fbeeper" [disabled]="sendSMSForm.invalid">
                    <div *ngIf="btnSSMS.disabled && sendSMSForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Envoyer
                </button>
            </div>
        </div>
    </form>
</p-dialog>