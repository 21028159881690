import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { takeUntil, Subject } from 'rxjs';
import { NotifyClientService } from 'src/app/services/notify-client.service';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-send-sms',
    templateUrl: './send-sms.component.html',
    styleUrls: ['./send-sms.component.scss'],
    standalone: false
})
export class SendSmsComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() isSendSMS: boolean = false;
  @Input() delivery_id: any;
  @Input() type: any = "sms";
  @Input() selectedOrder: any;
  @Output() closeSendSMSEvent = new EventEmitter<boolean>();

  currentUserLocation: any = <any>{ }
  phoneCallRegex = /^213/i;

  listMessages: any;

  sendSMSForm: any;
  initSendSMSForm(){
    this.sendSMSForm = new FormGroup({
      phone: new FormControl('', [Validators.required]),
      message_select: new FormControl(''),
      message: new FormControl('', [Validators.required]),
    })
  }

  constructor(
    private notifyClientService: NotifyClientService,
    private store: Store,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
        this.phoneCallRegex = new RegExp('^' + this.currentUserLocation?.phone_int_prefix, 'i');
      }
    )

    this.initSendSMSForm();
    this.sendSMSForm.patchValue({
      phone: this.selectedOrder?.client?.phone,
    });

    /* let msg1 = "Le livreur de Wajeez à essayé de vous joindre "  + (this.selectedOrder?.order?.support_phone) ? ", veuillez le contacter au " : ""
      + (this.selectedOrder?.order?.support_phone)?.replace(",", " ou ") */

    let msg1 = "Wajeez a essayé de vous joindre " 
                + ((this.selectedOrder?.order?.support_phone) ? `, veuillez nous contacter au ${this.selectedOrder.order.support_phone.replace(",", " ou ")}` : "");

    let msg2 = "Notre Livreur Wajeez a essayé de vous joindre"
               +((this.selectedOrder?.staff?.phone)
                ? `, Merci donc de le rappeler sur ${this.selectedOrder.staff.phone.replace(this.phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}`
                : "");

    this.listMessages = [
      msg1,
      msg2
    ]
  }

  OnSelectMessage(){
    this.sendSMSForm.patchValue({
      message: this.sendSMSForm?.value?.message_select,
    });
  }

  submitSendSMS(btnS: any){
    btnS.disabled = true;

    const data: any = {
      use: this.type,
      message: this.sendSMSForm?.value?.message,
    }

    if(this.sendSMSForm.value.phone){
      data.phone = this.sendSMSForm.value.phone?.replace(this.phoneCallRegex, this.currentUserLocation?.phone_local_prefix);
    }

    if(!data?.message ){
      switch (this.sendSMSForm?.value?.message_select) {
        case "1":
          data.message = "Wajeez à essayé de vous joindre, veuillez nous contacter au 0550 00 00 00.";
          break;
        case "2":
          data.message = "Notre Livreur Wajeez a essayé de vous joindre, Merci donc de le rappeler sur 0550 00 00 00.";
          break;
      }
    }

    this.notifyClientService.sendSMS(data, this.selectedOrder?.id).subscribe(
      (res: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboard', severity:'success', summary:'Succès', detail: "Envoyé avec Succès"});
        btnS.disabled = false;
        this.isSendSMS = false;
      },
      (err: any)=>{
        this.messageService.clear();
        this.messageService.add({key: 'SendSMSToast', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        btnS.disabled = false;
      }
    );
  }

  closeModal(){
    this.isSendSMS = false;
    this.closeSendSMSEvent.emit(false)
  }


}
