<div class="row">
    <div class="col-12">
        <p-table #deliveriesTable [value]="deliveries" [globalFilterFields]="['1.name', '1.phone', '1.vehicle']" 
                responsiveLayout="scroll" [scrollable]="true"  scrollHeight="calc(100vh - 225px)">
            <ng-template pTemplate="caption">
                <div class="row">
                    <div class="col-4">
                        <input #searchFilter class="form-control" type="text" (input)="applyFilterGlobal($event, 'contains', deliveriesTable)" placeholder="Rechercher par nom, tel ou vehicle" />
                    </div>
                    <div class="offset-4 col-4 text-end">
                        <button class="btn btn-default-primeng-green" (click)="resetTable(deliveriesTable);">Réinitialiser</button>
                    </div>
                </div>
            </ng-template>
            <!-- <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col style="width: 15%">
                    <col style="width: 20%">
                    <col style="width: 20%">
                    <col style="width: 15%">
                    <col style="width: 15%">
                    <col style="width: 15%">
                </colgroup>
            </ng-template> -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 10%" class="text-center"></th>
                    <th style="min-width: 22.5%" class="text-start" pSortableColumn="1.name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Livreur
                            <p-sortIcon field="1.name"></p-sortIcon>
                        </div>
                    </th>
                    <th style="min-width: 22.5%"> Tel </th>
                    <th style="min-width: 15%" class="text-center"  pSortableColumn="1.deliveries_today">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Livrée / 1h
                            <p-sortIcon field="1.deliveries_today"></p-sortIcon>
                        </div>
                    </th>
                    <th style="min-width: 15%" class="text-center"  pSortableColumn="1.pendingOrdersLength">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            En cours
                            <p-sortIcon field="1.pendingOrdersLength"></p-sortIcon>
                        </div>
                    </th>
                    <th style="min-width: 15%" class="text-center"  pSortableColumn="1.online">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Statut
                            <p-sortIcon field="1.online"></p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-delivery>
                <tr>
                    <td style="min-width: 10%" class="text-center">
                        <a  style="color: var(--info-hub-fbeeper); text-decoration: none;"
                            [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (delivery[1]?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--success-hub-fbeeper)" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16" style="cursor: pointer;">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </a>
                    </td>
                    <td style="min-width: 22.5%" class="d-flex align-items-center">
                        <div *ngIf="!delivery[1]?.available"
                            class="working_status me-1"
                            title="Non disponible">
                        </div>
                        <span [title]="delivery[1]?.old_app ? 'Ancienne application' : ''" [ngStyle]="{'color': delivery[1]?.old_app ? 'var(--danger-hub-fbeeper)' : 'inherit'}">{{delivery[1]?.name}}</span>
                        <span class="ms-1">
                            <svg *ngIf="delivery[1]?.vehicle == 'voiture'" version="1.1" id="Capa_1" width="16" height="16" fill="var(--green-hub-fbeeper)" xmlns="http://www.w3.org/2000/svg" 
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 417.885 417.885" style="enable-background:new 0 0 417.885 417.885;" xml:space="preserve">
                                <path style="stroke:#000000;stroke-width:0;stroke-linecap:round;stroke-linejoin:round;" d="M414.986,147.236 c-0.994-2.892-4.515-5.142-7.454-5.142c-12.728,0-25.456,0-38.183,0c-2.451,0-5.3,1.566-6.758,3.762 c-8.031-19.086-17.269-38.732-26.146-52.353c-1.597-2.451-4.003-4.265-6.8-5.125c-1.617-0.497-40.534-12.189-120.704-12.189 c-80.168,0-119.084,11.692-120.701,12.189c-2.796,0.86-5.203,2.674-6.8,5.126c-8.877,13.623-18.115,33.269-26.146,52.353 c-1.457-2.196-4.307-3.763-6.758-3.763c-12.728,0-25.455,0-38.183,0c-2.939,0-6.46,2.25-7.455,5.142 c-3.866,11.258-3.864,17.704,0.001,28.963c0.994,2.892,4.515,5.142,7.454,5.142c6.692,0,13.383,0,20.075,0 c-9.345,9.35-15.142,22.246-15.142,36.478c0,0.665,0.045,1.329,0.134,1.989l1.208,8.878c0.402,2.958,1.674,5.602,3.522,7.713v20.187 h-1.166c-2.761,0-5,2.239-5,5v59.819c0,11.189,9.103,20.291,20.291,20.291h40.573c11.188,0,20.291-9.103,20.291-20.291v-25.709 h229.689v25.709c0,11.189,9.103,20.291,20.291,20.291h40.573c11.189,0,20.291-9.103,20.291-20.291v-59.819c0-2.761-2.239-5-5-5 h-1.167v-18.224c2.973-2.299,5.066-5.709,5.606-9.677l1.207-8.878c0.09-0.659,0.134-1.324,0.134-1.989 c0-14.232-5.797-27.128-15.142-36.478c5.303,0,10.605,0,15.908,0c2.939,0,6.46-2.25,7.454-5.142 C418.853,164.94,418.851,158.494,414.986,147.236z M99.886,111.177c11.789-2.815,47.803-9.975,109.056-9.975 c61.277,0,97.297,7.165,109.058,9.974c8.35,13.875,17.125,33.463,24.604,51.831c-19.639-3.19-58.322-6.829-132.619-6.829 c-78.96,0-117.08,4.171-134.996,7.544C82.528,145.154,91.423,125.24,99.886,111.177z M30.659,284.529v-13h35.5l3.749,13H30.659z M30.151,256.586V232.01l68.991,4.442l6.732,20.134H30.151z M294.787,282.517H125.182l-5.197-16.892h180L294.787,282.517z M303.551,256.586H116.418l-5.061-15.135h197.254L303.551,256.586z M108.277,231.967l-5.167-16.793h213.75l-5.167,16.793H108.277z M389.309,284.529h-39.249l3.749-13h35.499V284.529z M389.818,256.586h-75.723l6.732-20.134l68.991-4.442V256.586z"/>
                            </svg>
                            <svg *ngIf="delivery[1]?.vehicle == 'moto'" width="16" height="16" fill="var(--green-hub-fbeeper)" 
                                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 460.427 460.427" style="enable-background:new 0 0 460.427 460.427;" xml:space="preserve">
                                <g id="XMLID_800_">
                                    <g>
                                        <g>
                                            <circle cx="225.108" cy="41.302" r="38.373"/>
                                            <path d="M397.111,330.868c-7.377,0-14.458,1.281-21.047,3.611l-3.12-9.271c6.069-1.88,12.506-2.914,19.175-2.957 c8.102-0.052,15.106-6.153,15.609-14.239c0.549-8.84-6.458-16.18-15.179-16.18c-10.213,0-20.059,1.595-29.309,4.541l-20.71-61.54 h9.315c3.709,7.964,10.934,13.96,19.685,15.978c3.329,0.768,6.52-1.762,6.52-5.191v-45.936c0-3.406-3.164-5.96-6.484-5.199 c-8.767,2.01-16.007,8.012-19.721,15.987h-4.463c-2.762,3.551-6.192,6.541-10.09,8.794c-9.936,5.744-18.371,4.656-24.252,4.314 c1.41,4.189,9.775,29.046,11.571,34.383c-1.71,1.595,3.555-6.344-68.423,106.855h-0.684c-2.564,8.595-6.261,15.549-14.333,21.473 c-1.468,1.077-3.017,2.038-4.623,2.888h19.837c8.186,0,15.805-4.183,20.2-11.09l57.851-90.93l6.585,19.567 c-27.031,17.072-45.069,47.145-45.247,81.37c-0.043,8.292,6.381,15.424,14.668,15.71c8.646,0.299,15.749-6.621,15.749-15.2 c0-20.938,9.758-39.629,24.953-51.8l3.515,10.444c-13.426,12.156-21.633,29.974-20.806,49.648 c1.368,32.53,27.712,59.008,60.235,60.529c36.281,1.697,66.339-27.33,66.339-63.245 C460.427,359.272,432.024,330.868,397.111,330.868z M397.111,416.942c-12.549,0-22.758-10.209-22.758-22.758 s10.209-22.758,22.758-22.758s22.758,10.209,22.758,22.758C419.869,406.733,409.66,416.942,397.111,416.942z"/>
                                            <path d="M269.437,259.727c3.117-10.448-2.336-21.534-12.515-25.441l-23.595-9.057l1.407-6.988 c-7.085-2.401-12.47-8.33-14.186-15.588l-13.618-57.806l28.632,49.039c2.935,5.028,8.193,8.252,14.005,8.59l69.342,4.03 c9.601,0.554,17.808-6.774,18.365-16.348c0.557-9.585-6.762-17.807-16.348-18.365l-60.037-3.489l-23.933-40.989l13.567,12.518 l1.624-8.065c2.827-14.035-6.26-27.703-20.294-30.53l-45.317-9.127c-14.035-2.826-27.703,6.26-30.53,20.294l-14.561,72.305 v-69.587c0-4.846-3.929-8.775-8.775-8.775H32.603c-4.846,0-8.775,3.929-8.775,8.775v127.689h-6.084 c-9.8,0-17.744,7.944-17.744,17.744c0,9.8,7.944,17.744,17.744,17.744h73.004v27.041c-29.827,11.281-52.235,37.663-57.884,69.823 c-1.275,7.26,4.317,13.919,11.7,13.919h15.524c-0.135,1.684-0.223,3.381-0.223,5.099c0,34.912,28.403,63.316,63.316,63.316 c34.912,0,63.316-28.403,63.316-63.316c0-1.686-0.086-3.351-0.216-5.004h15.02c-15.51-8.246-23.512-26.573-18.347-43.889 l19.983-66.989h17.417l-21.406,71.76c-3.294,11.041,2.987,22.662,14.028,25.956c11.042,3.294,22.663-2.988,25.956-14.028 L269.437,259.727z M123.18,416.942c-12.549,0-22.758-10.209-22.758-22.758c0-1.753,0.206-3.458,0.583-5.099h44.35 c0.377,1.64,0.583,3.345,0.583,5.099C145.938,406.733,135.728,416.942,123.18,416.942z M141.446,242.814v-17.198 c2.926,6.698,7.462,12.621,13.223,17.198H141.446z"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <svg *ngIf="delivery[1]?.vehicle == 'velo'" width="16" height="16" fill="var(--green-hub-fbeeper)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 491.351 491.351" style="enable-background:new 0 0 491.351 491.351;" xml:space="preserve">
                                <path id="XMLID_188_" d="M381.559,182.007c-0.895,0-1.755,0.115-2.632,0.138l-14.934-87.322c-0.809-4.71-3.613-8.842-7.674-11.355 c-4.051-2.511-8.998-3.154-13.582-1.772l-44.975,13.622c-8.792,2.667-13.763,11.963-11.097,20.771 c2.667,8.801,11.992,13.789,20.757,11.111l26.901-8.15l6.167,35.949H198.895v-7.076l17.403-4.393 c6.916-1.741,11.845-8.19,11.433-15.575c-0.464-8.369-7.639-14.77-16.018-14.29l-64.285,3.653c-1.282,0.08-2.701,0.292-4.001,0.616 c-11.415,2.889-18.349,14.487-15.467,25.906c2.882,11.419,14.495,18.339,25.911,15.458l11.707-2.959v10.815l-13.694,23.586 c-12.964-5.61-27.142-8.733-42.032-8.733C49.282,182.007,0,233.227,0,296.184C0,359.151,49.282,410.37,109.852,410.37 c55.081,0,100.708-42.453,108.494-97.529h17.763c4.732,0,9.239-2.017,12.396-5.547l24.432-27.287 c-0.732,5.304-1.239,10.673-1.239,16.177c0,62.968,49.291,114.187,109.861,114.187c60.543,0,109.792-51.219,109.792-114.187 C491.351,233.227,442.102,182.007,381.559,182.007z M109.852,377.058c-42.203,0-76.544-36.28-76.544-80.874 c0-44.591,34.341-80.864,76.544-80.864c8.783,0,17.084,1.895,24.956,4.789l-39.365,67.709c-2.994,5.148-3.011,11.508-0.035,16.672 c2.959,5.165,8.474,8.352,14.444,8.352h74.824C177.39,349.464,146.62,377.058,109.852,377.058z M184.676,279.528h-45.868 l24.078-41.406C173.682,249.151,181.476,263.423,184.676,279.528z M228.66,279.528h-10.314 c-4.001-28.383-17.988-53.433-38.255-71.018l11.743-20.202h118.464L228.66,279.528z M381.559,377.058 c-42.211,0-76.544-36.28-76.544-80.874c0-33.481,19.355-62.272,46.874-74.536l13.239,77.351c1.403,8.118,8.431,13.842,16.397,13.842 C458.043,340.777,423.719,377.058,381.559,377.058z"/>
                            </svg>
                        </span>
                        <span *ngIf="delivery[1]?.errands" class="ms-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff622e" class="bi bi-cart4" viewBox="0 0 16 16">
                                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
                            </svg>
                        </span>
                    </td>
                    <td style="min-width: 22.5%">
                        <app-dropdown-additional-phones [phone]="(delivery[1]?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)"
                                                        [phonesList]="delivery[1]?.data?.additional_phones"
                                                        (openAdditionalPhonesModalEvent)="openAdditionalPhonesModal(delivery)">
                        </app-dropdown-additional-phones>
                    </td>
                    <td style="min-width: 15%" class="text-center">
                        <span style="color: var(--success-hub-fbeeper);" title="nombre de livraison livrées au cours de la dernière heure">{{delivery[1]?.deliveries_today}}</span>
                    </td>
                    <td style="min-width: 15%" class="text-center">
                        <span class="text-break" [pTooltip]="delivery[1]?.pendingOrdersToText +'\n' + delivery[1]?.pendingErrandsToText" tooltipPosition="left" [escape]="false" style="color: var(--info-hub-fbeeper);">
                            <!-- {{delivery[1]?.remaining_deliveries}} -->
                            {{delivery[1]?.pendingOrdersLength + delivery[1]?.pendingErrandsLength }}
                        </span>
                    </td>
                    <!-- <td style="min-width: 5%">
                        <div class="workaday_status">
                            <div class="working_status mb-1"
                                title="En service"
                                [ngStyle]="{'background-color' : (delivery[1]?.working) ? 'var(--Primary_base_05)' : 'var(--Shade_04)'}">
                            </div>
                            <div class="working_status"
                                title="Disponible"
                                [ngStyle]="{'background-color' : (delivery[1]?.working && delivery[1]?.available) ? 'var(--Primary_base_01)' 
                                : (delivery[1]?.working && !delivery[1]?.available) ? 'var(--Primary_base_02)' : 'var(--Shade_04)'}">
                            </div>
                        </div>
                    </td> -->
                    <td style="min-width: 15%" class="text-center">
                        <img [src]="delivery[1]?.cached_photo || '../../../assets/images/user.png'" alt="staff" height="35px" width="35px" style="border-radius: 50%;" 
                            [ngStyle]="{'padding': '1px', 'border': 'solid 2px', 'border-color': delivery[1]?.online ? 'var(--success-hub-fbeeper)' : 'var(--danger-hub-fbeeper)'}"
                            [title]="delivery[1]?.online ? '' : delivery[1]?.disconnected_at | date: 'dd/MM HH:mm'">
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7" class="text-center">Aucun restaurant.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<app-additional-phones  *ngIf="additionalPhonesModal"
                        [additionalPhonesModal]="additionalPhonesModal"
                        [type]="selectedType"
                        [dataTable]="selectedDelivery[1]?.data?.additional_phones"
                        [targetId]="selectedDelivery[1]?.id || selectedDelivery[0]"
                        [additionalPhonesChanges]="currentDelivery"
                        (additionalPhonesModalEvent)="additionalPhonesModalEvent()">
</app-additional-phones>