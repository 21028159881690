import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { RegionsService } from 'src/app/services/regions.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import * as fromUser from 'src/app/ngrx/user/user.selectors'
import * as fromHub from 'src/app/ngrx/hub/hub.selectors'
import * as fromStatus from 'src/app/ngrx/status/status.selectors'
import * as fromOnlineVisitors from 'src/app/ngrx/online-visitors/online-visitors.selectors'
import * as fromActiveDeliveries from 'src/app/ngrx/active-deliveries/active-deliveries.selectors'
import * as OnlineVisitorsActions from 'src/app/ngrx/online-visitors/online-visitors.actions'
import { KeyValue } from '@angular/common';
import { OtherService } from 'src/app/services/other.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HubService } from 'src/app/services/hub.service';
import { MessageService } from 'primeng/api';
import * as fromHubManager from 'src/app/ngrx/status/hub-manager/hub-manager.selectors'
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import { Subject, Observable } from 'rxjs';
import { Partitions } from 'src/app/interfaces/partitions';
import { GlobalInjectableService } from 'src/app/services/global-injectable.service';
import * as fromActiveErrands from 'src/app/ngrx/active-errands/active-errands.selectors'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>()
  confirmationModal: boolean = false;
  pauseHubModal: boolean = false;
  currentUser: any = {}
  currentHub: any = {}
  //currentHubStatus: any = {}
  currentHubManager: any = {};
  weather: any = {}
  onlineVisitors: any = {}
  currentActiveDeliveries: any = {}
  currentActiveErrands: any = {}
  complete: boolean = false;
  runHubModal: boolean = false;
  timeOutSubscription: any;

  currentUserLocation: any = <any>{ }

  currentPartition: Partitions = Partitions.all;

  favIcon = document.querySelector('#favIcon') as HTMLLinkElement; 

  pauseHubForm: any;
  initPauseHubForm(){
    let initDate = new Date()
    initDate.setMinutes(0)
    initDate.setHours(11);
    this.pauseHubForm = new FormGroup({
      pause_reason: new FormControl(null, [Validators.required]),
      accept_delivery: new FormControl(''),
      accept_delivery_at: new FormControl('', [Validators.required]),
      accept_delivery_at_time: new FormControl(initDate),
    })
  }

  constructor(
    private router: Router,
    private regionsService: RegionsService,
    private store: Store<any>,
    private firebaseService: FirebaseService,
    private activatedRoute: ActivatedRoute, 
    public otherService: OtherService,
    private hubService: HubService,
    private messageService: MessageService,
    private globalInjectableService: GlobalInjectableService,
  ) { }

  ngOnInit(): void {
    this.initPauseHubForm()

    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((routeParams: any) => {
      this.retrieveOnlineVisitors(routeParams.hub_id);
    });

    this.store.pipe(select(fromUser.getCurrentUser), takeUntil(this.destroy$)).subscribe(
      (user: any) => {
        this.currentUser = user;
      }
    )

    this.store.pipe(select(fromHub.getCurrentHub), takeUntil(this.destroy$)).subscribe(
      (hub: any) => {
        this.currentHub = hub;
        if(this.currentHub?.profile?.hub){
          this.getWeather(this.currentHub?.profile?.hub?.region?.id);
        }
      }
    )

    this.store.pipe(select(fromHubManager.getCurrentHubManager), takeUntil(this.destroy$)).subscribe(
      (hubManager: any) => {
        this.currentHubManager = hubManager;
      }
    )

    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
        //console.log(data)
      }
    )

    this.store.pipe(select(fromActiveDeliveries.getCurrentActiveDeliveries), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentActiveDeliveries = data;
        
        if(Object.keys(this.currentActiveDeliveries?.pending || {})?.length > 0
          || Object.keys(this.currentActiveErrands?.pending || {})?.length > 0){
          this.favIcon.href = `assets/icons/red_circle.ico`; 
        }else{
          this.favIcon.href = `favicon.ico`; 
        }
      }
    )

    this.store.pipe(select(fromActiveErrands.getCurrentActiveErrands), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentActiveErrands = data;
        
        if(Object.keys(this.currentActiveDeliveries?.pending || {})?.length > 0
          || Object.keys(this.currentActiveErrands?.pending || {})?.length > 0){
          this.favIcon.href = `assets/icons/red_circle.ico`; 
        }else{
          this.favIcon.href = `favicon.ico`; 
        }
      }
    )

    this.globalInjectableService.currentCurrentPartition.subscribe(
      (data: Partitions) => {
        this.currentPartition = data;
      }
    )
  }

  getWeather(regionId: number){
    this.regionsService.getWeatherByRegion(regionId).pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        this.weather = res;
      }
    )

    this.timeOutSubscription = setTimeout(() => this.getWeather(regionId), 1800000);
  }

  retrieveOnlineVisitors(hub_id: any): void {
    this.firebaseService.getOnlineVisitors(hub_id).subscribe((data: any) => {
      //console.log(data)
      this.store.dispatch(new OnlineVisitorsActions.LoadCurrentHubOnlineVisitorsSuccess({data: data}));
      //this.onlineVisitors = data;
    });

    this.store.pipe(select(fromOnlineVisitors.getCurrentHubOnlineVisitors), takeUntil(this.destroy$)).subscribe(
      (OnlineVisitors: any) =>{
        this.onlineVisitors = OnlineVisitors;
      }
    )
  }

  /* removeSMSMsg(key: any){
    this.firebaseService.removeHubManagerReceivedSMS(this.currentHub?.profile?.hub?.region?.id, this.currentHub?.profile?.hub_id, key)
  } */

  submitPauseHub(btnS: any){
    btnS.disabled = true;

    if (this.pauseHubForm.valid) {
      let temp: any = new Date()
      switch (this.pauseHubForm.get('accept_delivery_at').value) {
        case '10m':
          temp.setMinutes(temp.getMinutes() + 10)
          break;
        case '30m':
          temp.setMinutes(temp.getMinutes() + 30)
          break;
        case '1h':
          temp.setHours(temp.getHours() + 1)
          break;
        case '3h':
          temp.setHours(temp.getHours() + 3)
          break;
        case 'today':
          temp = this.pauseHubForm.get('accept_delivery_at_time').value
          break;
        case '24h':
          let tomorrow = new Date(this.pauseHubForm.get('accept_delivery_at_time').value)
          tomorrow.setDate(tomorrow.getDate() + 1)
          temp = tomorrow
          break;
        default:
          temp = null
          break;
      }

      const data = <any>{
        pause_reason: this.pauseHubForm.get('pause_reason').value,
        accept_delivery: 0,
        accept_delivery_at: this.otherService.dateFormat(temp)
      }

      this.hubService.updateHub(data, this.currentHub?.profile?.hub_id).subscribe(
        (res: any) => {
          btnS.disabled = false;
          this.pauseHubModal = false;
        },
        (err: any) => {
          btnS.disabled = false;
          this.messageService.add({key: 'toast1', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)})
        }
      )

    }else{
      Object.keys(this.pauseHubForm.controls).forEach(field => {
        const control = this.pauseHubForm.get(field);
        control.markAsTouched({ onlySelf: true });
       });
       this.complete = false;
       btnS.disabled = false;
    }
  }

  runHubSubmit(btnS: any){
    const data = <any>{
      pause_reason: null,
      accept_delivery: 1,
      accept_delivery_at: null
    }

    this.hubService.updateHub(data, this.currentHub?.profile?.hub_id).subscribe(
      (res: any) => {
        btnS.disabled = false;
        this.runHubModal = false;
      },
      (err: any) => {
        btnS.disabled = false;
        this.messageService.add({key: 'toast1', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)})
      }
    )
  }

  logout(){
    localStorage.removeItem("token_auth_agent");
    localStorage.removeItem("sub_token_hub_agent");
    this.confirmationModal = false;
    this.router.navigate(["/login"]);
  }

  changePartition(partition: number){
    switch (partition) {
      case 1:
        this.globalInjectableService.changeCurrentPartition(Partitions.part_1);
        break;
      case 2:
        this.globalInjectableService.changeCurrentPartition(Partitions.part_2);
        break;
      case 3:
        this.globalInjectableService.changeCurrentPartition(Partitions.part_1_hide_2);
        break;
      case 4:
        this.globalInjectableService.changeCurrentPartition(Partitions.part_2_hide_1);
        break;
      default:
        this.globalInjectableService.changeCurrentPartition(Partitions.all);
        break;
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timeOutSubscription)
    this.favIcon.href = `favicon.ico`;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
