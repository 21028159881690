import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors';
import { ClientTicketService } from 'src/app/services/client-ticket.service';
import { OtherService } from 'src/app/services/other.service';
import { types } from 'util';

@Component({
    selector: 'app-client-ticket',
    templateUrl: './client-ticket.component.html',
    styleUrls: ['./client-ticket.component.scss'],
    standalone: false
})
export class ClientTicketComponent {
  @Input() selectedOrder = <any>{};
  @Input() clientTicket: boolean = false;
  @Output() clientTicketEvent = new EventEmitter<boolean>();

  destroy$: Subject<boolean> = new Subject<boolean>()

  types: any[] = []
  sources = [{label: "Rating", value: "rating"}, {label: "réseaux sociaux", value: "social_media"}, {label: "appel entrant", value: "incoming_call"}]
  reasons: any[] = []

  currentUserLocation: any = <any>{ }
  phoneCallRegex = /^213/i;
  phoneLocalRegex = /^0/i;

  clientTicketForm: any;
  initClientTicketForm(){
    this.clientTicketForm = new FormGroup({
      store_id: new FormControl((this.selectedOrder?.order?.store_id || null)),
      client_id: new FormControl((this.selectedOrder?.client?.id || null)),
      order_id: new FormControl(this.selectedOrder?.order_id || null),
      order_number: new FormControl({value: (this.selectedOrder?.order?.order_number || null), disabled: true}),
      hub_id: new FormControl(this.selectedOrder?.hub_id || null),
      delivery_staff_id: new FormControl(this.selectedOrder?.staff_id || null),
      
      client_phone: new FormControl(this.selectedOrder?.client?.phone || ''),
      source: new FormControl("", [Validators.required]),
      reason: new FormControl(""),
      type: new FormControl(''),
      description: new FormControl('', [Validators.required]),
      //report_to: new FormControl(""),
      notify_backoffice:new FormControl(false),
      notify_fleet: new FormControl(false),
    })
  }

  constructor(
    private clientTicketService: ClientTicketService,
    private messageService: MessageService,
    public otherService: OtherService,
    private store: Store,
  ) { 
    
  }

  ngOnInit(): void {
    this.initClientTicketForm();
    this.geTicketInfo()

    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
        this.phoneCallRegex = new RegExp('^' + this.currentUserLocation?.phone_int_prefix, 'i');
        this.phoneLocalRegex = new RegExp('^' + this.currentUserLocation?.phone_local_prefix, 'i');
      }
    )
  }

  geTicketInfo(){
    this.clientTicketService.getTicketInfo().pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        this.types = res?.types;
        this.sources = res?.sources;
      },
      (err: any) => {
        this.messageService.clear();
        this.messageService.add({key: 'KeyHub', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    );
  }

  onTypeChange(event: any){
    //let target = (event?.target?.value)?.split(": ")[1]
    let target = this.clientTicketForm.get('type')?.value

    if(!target) {
      this.clientTicketForm.controls['reason'].disable();
    }else{
      this.clientTicketForm.controls['reason'].enable();
      this.reasons = this.types[target]?.reasons
    }
  }

  submitClientTicket(btnS: any){
    btnS.disabled = true;
    console.log(this.clientTicketForm.value)
    if (this.clientTicketForm.valid) {
      /* const data = <any> {
        store_id: this.selectedOrder?.order?.store_id,
        description: this.selectedOrder?.order?.order_number + " - " + this.clientTicketForm.get('description').value,
        type: this.clientTicketForm.get('type').value
      } */

      this.clientTicketForm.patchValue({
        client_phone: this.clientTicketForm?.value?.client_phone?.replace(this.phoneLocalRegex, this.currentUserLocation?.phone_int_prefix),
      })

      this.clientTicketService.createClientTicket(this.clientTicketForm.value).subscribe(
        (res: any) => {
          this.messageService.add({key: 'KeyHub', severity:'success', summary:'Succès', detail: "Envoyée avec succès!"});
          btnS.disabled = false;
          this.closeModal(true);
        },
        (err: any) => {
          if(btnS){
            btnS.disabled = false;
          }
          this.messageService.add({key: 'clientTicketToast', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        }
      );
    }else {
      this.clientTicketForm.markAllAsTouched();
      this.messageService.add({key: 'clientTicketToast', severity:'error', summary:'Erreur', detail: "Invalid!"});
      btnS.disabled = false;
    }
  }

  closeModal(e: any){
    this.clientTicket = false;
    this.clientTicketEvent.emit(e)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
