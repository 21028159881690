import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './stores.reducer'

const getStoresFeatureState = createFeatureSelector<State>('stores')

export const getCurrentStores = createSelector(
    getStoresFeatureState,
    (state: any) => state.stores
)

export const getCurrentError = createSelector(
    getStoresFeatureState,
    (state: any) => state.error
)