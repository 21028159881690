import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderStatusDetail' })
export class OrderStatusDetailPipe implements PipeTransform {

  transform(order: any): any {
    let statusText = order?.status
    switch (order?.status) {
      case 'accepted':
        if (order?.order_status === "accepted") {
          statusText = "Acceptée";
          break;
        } else {
          statusText = order?.order_status;
          break;
        }
      case 'pending':
        if (order?.order_status === "confirmed") {
          statusText = "En attente";
          break;
        } else if(order?.order_status === "waiting"){
          statusText = "Planifiée";
          break;
        } else {
          statusText = "À Confirmer";
        }
        break;
      case "order_refused":
        statusText = "Refusée";
        break;
      case "order_canceled":
        statusText = "Annulée";
        break;
      case "order_rejected":
        statusText = "Rejetée";
        break;
      default:
        statusText = order?.order_status;

    }
    return statusText;
  }

}
