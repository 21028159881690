import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'deliveryStatus' })
export class DeliveryStatusPipe implements PipeTransform {

  transform(order: any): any {
    let statusText = order?.status
    switch (order?.status) {
      case 'accepted':
       statusText = 'En attente Livreur';
        break;
      case "delivery_accepted":
       statusText = 'Acceptée';
        break;
      case "pickup":
       statusText = "En livraison";
        break;
      case "delivered":
       statusText = "Livrée";
        break;
      case "not-delivered":
        statusText = "Non livrée";
        break;
      case "canceled":
        statusText = "Annuler";
        break;
      case "order_refused":
        statusText = "Refusée";
        break;
      case "order_canceled":
        statusText = "Annulée";
        break;
      case "order_rejected":
        statusText = "Rejetée";
        break;
      case 'pending':
       statusText = "À Confirmer";
        break;

      default:
       statusText = order?.status;
    }
    return statusText;
  }

}
