<div class="btn-group dropstart">
    <!-- <button class="btn dropdown-toggle p-0" style="color: #495057;" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{phone}}
    </button> -->
    <button type="button" class="btn p-0 pe-1 dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" 
            data-bs-reference="parent" style="color: #495057;" data-bs-auto-close="outside"
            [ngStyle]="{'color': phonesList?.length > 0 ? 'var(--danger-hub-fbeeper)' : '#495057'}">
      <span class="visually-hidden">Toggle Dropdown</span>
    </button>
    <span style="color: #495057;" class="p-1">{{phone}}</span>
    <ul class="dropdown-menu">
        <li class="dropdown-item" style="color: var(--success-hub-fbeeper); cursor: pointer;" (click)="openAdditionalPhonesModal()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
            </svg>
            &nbsp;
            Ajouter un n° de tel
        </li>
        <li class="dropdown-item" *ngFor="let phone of phonesList | keyvalue:otherService.onCompare">
            {{phone?.value?.label + ': '}}
            <a style="color: var(--info-hub-fbeeper); text-decoration: none;" 
                [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (phone?.value?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                {{(phone?.value?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)}}
            </a>
            <p class="mb-0" *ngIf="phone?.value?.note"> <small> <strong>Note: </strong>{{phone?.value?.note}} </small></p>

        </li>
    </ul>
</div>
