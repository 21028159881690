import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyClientService {

  constructor(private httpserviceService: HttpserviceService) { }

  sendSMS(data: any, delivery_id: number){
    return this.httpserviceService.post(data, "notify_client/" + delivery_id );
  }
}
