import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { StoreTicketService } from 'src/app/services/store-ticket.service';

@Component({
    selector: 'app-store-ticket',
    templateUrl: './store-ticket.component.html',
    styleUrls: ['./store-ticket.component.scss'],
    standalone: false
})
export class StoreTicketComponent implements OnInit {

  
  @Input() store_id = null;
  @Input() order_number = null;
  @Input() storeTicket: boolean = false;
  @Output() storeTicketEvent = new EventEmitter<boolean>();

  destroy$: Subject<boolean> = new Subject<boolean>()
  storeTicketTypes: any = null;

  storeTicketForm: any;
  initStoreTicketForm(){
    this.storeTicketForm = new FormGroup({
      store_id: new FormControl(this.store_id, [Validators.required]),
      order_number: new FormControl({value: this.order_number, disabled: true}),
      description: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required])
    })
  }

  constructor(
    private StoreTicketService: StoreTicketService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.initStoreTicketForm();
    this.getStoreTickets();
  }

  getStoreTickets(){
    this.StoreTicketService.getStoreTicketsTypes().subscribe(
      (res: any) => {
        this.storeTicketTypes = res.data;
      },
      (err: any) => {
        this.messageService.add({key: 'storeTicketToast', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    )
  }

  submitStoreTicket(btnS: any){
    btnS.disabled = true;
    if (this.storeTicketForm.valid) {
      const data = <any> {
        store_id: this.store_id,
        description: this.order_number + " - " + this.storeTicketForm.get('description').value,
        type: this.storeTicketForm.get('type').value
      }
      this.StoreTicketService.createStoreTicket(data).subscribe(
        (res: any) => {
          this.messageService.add({key: 'KeyHub', severity:'success', summary:'Succès', detail: "Appel d'offre partagé"});
          btnS.disabled = false;
          this.closeModal();
        },
        (err: any) => {
          if(btnS){
            btnS.disabled = false;
          }
          this.messageService.add({key: 'storeTicketToast', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        }
      );
    }else {
      this.storeTicketForm.markAllAsTouched();
      this.messageService.add({key: 'storeTicketToast', severity:'error', summary:'Erreur', detail: "Invalid!"});
      btnS.disabled = false;
    }
  }

  closeModal(){
    this.storeTicket = false;
    this.storeTicketEvent.emit(false)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
