<p-dialog header="Envoyer une réclamation à l'administration" [(visible)]="storeTicket" [modal]="true" styleClass="dialog35"
    [baseZIndex]="10000" [draggable]="true" [resizable]="false" [closable]="true" (onHide)="closeModal()">
    <p-toast key="storeTicketToast"></p-toast>
    <form [formGroup]="storeTicketForm" (ngSubmit)="submitStoreTicket(btnSST)">
        <div class="row">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Type <span class="text-danger">* </span></label>
                <div *ngIf="!storeTicketTypes" class="spinner-border ms-1"
                    style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" role="status">
                </div>
                <select formControlName="type" class="form-select">
                    <option *ngFor="let type of storeTicketTypes" [ngValue]="type.key">{{type.name}}</option>
                </select>
                <small class="form-text text-danger"
                        *ngIf="storeTicketForm.controls.type.invalid && storeTicketForm.controls.type.touched && storeTicketForm.controls.type.errors['required']">
                        Type obligatoire!
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Numéro de commande <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="order_number">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="dzt-grey-color mb-0">Description <span class="text-danger">*</span></label>
                <textarea type="text" rows="5" class="form-control" formControlName="description"></textarea>
                <small class="form-text text-danger"
                        *ngIf="storeTicketForm.controls.description.invalid && storeTicketForm.controls.description.touched && storeTicketForm.controls.description.errors['required']">
                        Description obligatoire!
                </small>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn btn-default-fbeeper me-3"
                    (click)="closeModal()">Annuler</button>
                <button type="submit" class="btn btn-success-fbeeper" #btnSST>
                    <div *ngIf="btnSST.disabled && storeTicketForm.valid" class="spinner-border"
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" role="status">
                    </div>
                    Partager
                </button>
            </div>
        </div>
    </form>
</p-dialog>