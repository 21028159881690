import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OtherService } from 'src/app/services/other.service';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import { select, Store } from '@ngrx/store';
import { ErrandsService } from 'src/app/services/errands.service';

@Component({
    selector: 'app-errands-panel',
    templateUrl: './errands-panel.component.html',
    styleUrls: ['./errands-panel.component.scss'],
    standalone: false
})
export class ErrandsPanelComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() selectedOrder: any = null;
  @Input() ActiveDeliveries: any = [];
  @Input() showErrandsPanel: boolean = true;
  @Output() selectedOrderEvent = new EventEmitter<boolean>();

  loading: boolean = false;

  orders: any = [];

  currentUserLocation: any = <any>{ }

  per_page: number = 0;
  current_page: number = 0;
  total: number = 0;
  total_pages: number = 0;
  first: any = 0;

  queryParams: string = "";

  filterHistoryErrandsForm: any
  initFilterHistoryErrandsForm(){
    this.filterHistoryErrandsForm = new FormGroup({
      //order_number: new FormControl(''),
      status: new FormControl(""),
      start_date: new FormControl(""),
      end_date: new FormControl(""),
    });
  }
  
  constructor(
    public otherService: OtherService,
    private errandsService: ErrandsService,
    private messageService: MessageService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
      }
    )

    this.getDeliveriesHistory();
    this.initFilterHistoryErrandsForm();
  }

  getDeliveriesHistory(page = 1, params=""){
    this.loading = true;
    let ordersIds = this.ActiveDeliveries.flatMap((x:any) => x?.id).join();
    this.errandsService.getErrands(page, params).pipe(takeUntil(this.destroy$)).subscribe(
      (res: any)=>{
        this.orders = res.data
        this.per_page = +res?.meta?.pagination?.per_page;
        this.current_page = +res?.meta?.pagination?.current_page;
        this.total = +res?.meta?.pagination?.total;
        this.total_pages = +res?.meta?.pagination?.total_pages;
        this.first = (this.current_page - 1) * this.per_page;
        this.loading = false;
      },
      (err: any)=>{
        this.loading = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    )
  }

  onPageChange(event: any){
    this.loading = true;
    this.getDeliveriesHistory(event.page+1, this.queryParams);
  }

  submitFilterHistoryErrands(btnS: any){
    const query = []

    /* if(this.filterHistoryErrandsForm.get('order_number').value){
      query.push("order_number:sw:" + this.filterHistoryErrandsForm.get('order_number').value);
    } */

    if(this.filterHistoryErrandsForm.get('status').value){
      query.push("status:" + this.filterHistoryErrandsForm.get('status').value);
    }

    if(this.filterHistoryErrandsForm.get('start_date').value){
      query.push("created:gte:" + this.filterHistoryErrandsForm.get('start_date').value);
    }

    if(this.filterHistoryErrandsForm.get('end_date').value){
      let date = new Date(this.filterHistoryErrandsForm.get('end_date').value)
      date.setDate(date.getDate() + 1)
      query.push("created:lse:" + date.toISOString().split('T')[0]);
    }
    
    this.queryParams = query.join(";")

    this.getDeliveriesHistory(1, this.queryParams)
  }

  refreshData(){
    this.getDeliveriesHistory(1, this.queryParams)
  }

  resetFilter(table: any){
    this.queryParams = "";
    table.clear();
    table.reset();
    this.getDeliveriesHistory();
  }

  selectOrder(order: any){
    order.errand = 1
    this.selectedOrderEvent.emit(order);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
