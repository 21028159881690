import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeliveriesService } from 'src/app/services/deliveries.service';
import { OtherService } from 'src/app/services/other.service';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import { select, Store } from '@ngrx/store';
import { getStaffList } from 'src/app/ngrx/staff/staff.selectors'
import { StoresService } from 'src/app/services/stores.service';
import * as fromHub from 'src/app/ngrx/hub/hub.selectors'

@Component({
    selector: 'app-history-panel',
    templateUrl: './history-panel.component.html',
    styleUrls: ['./history-panel.component.scss'],
    standalone: false
})
export class HistoryPanelComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() selectedOrder: any = null;
  @Input() ActiveDeliveries: any = [];
  @Input() filter: any = {};
  //@Input() staff: any = [];
  @Input() showHistoryPanel: boolean = true;
  @Output() selectedOrderEvent = new EventEmitter<boolean>();
  
  loading: boolean = false;
  
  orders: any = [];
  staffList: any = [];
  storeList: any = [];

  currentUserLocation: any = <any>{ }
  //currentDeliveries: any = {}
  currentHub: any = {}

  per_page: number = 0;
  current_page: number = 0;
  total: number = 0;
  total_pages: number = 0;
  first: any = 0;

  queryParams: string = "";
  
  @ViewChild('btnSFHO') btnSFHO!: ElementRef;


  filterHistoryOrdersForm: any
  initFilterHistoryOrdersForm(){
    this.filterHistoryOrdersForm = new FormGroup({
      order_number: new FormControl(''),
      status: new FormControl(null),
      start_date: new FormControl(""),
      end_date: new FormControl(""),
      staff: new FormControl(""),
      store: new FormControl(""),
    });
  }
  
  constructor(
    public otherService: OtherService,
    private deliveriesService: DeliveriesService,
    private messageService: MessageService,
    private storeService: StoresService,
    private storeRgrx: Store,
  ) { }

  ngOnInit(): void {
    this.storeRgrx.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
      }
    )

    this.storeRgrx.pipe(select(getStaffList), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.staffList = data;
      }
    )

    this.storeRgrx.pipe(select(fromHub.getCurrentHub), takeUntil(this.destroy$)).subscribe(
      (hub: any) =>{
        this.currentHub = hub;
      }
    )

    this.initFilterHistoryOrdersForm();

    if(this.filter?.key != "filter_by_store"){
      this.getDeliveriesHistory();
    }else{
      this.storeList = [
        {id: this.filter?.value?.id, name: this.filter?.value?.name}
      ]
      this.filterHistoryOrdersForm.patchValue({
        store: this.filter?.value?.id
      })
    }
  }

  filterByStore(){
    this.storeList = [
      {id: this.filter?.value?.id, name: this.filter?.value?.name}
    ]
    this.filterHistoryOrdersForm.patchValue({
      store: this.filter?.value?.id
    })
    this.submitFilterHistoryOrders(this.btnSFHO)
  }

  ngOnChanges(changes: SimpleChanges) {
    let data: any = changes?.filter
    if(data?.currentValue?.key == "filter_by_store"){
      this.filter = data?.currentValue;
      this.initFilterHistoryOrdersForm();
      this.filterByStore();
    }
    
  }

  getDeliveriesHistory(page = 1, params=""){
    this.loading = true;
    let ordersIds = this.ActiveDeliveries.flatMap((x:any) => x?.id).join();
    this.deliveriesService.getDeliveries(page, "&order_by=-completed&full_details=1&filters=status:ne:new;id:nn:" + ordersIds + params).pipe(takeUntil(this.destroy$)).subscribe(
      (res: any)=>{
        //this.selectedOrder = {... this.orders.filter((x: any) => x.id == this.selectedOrder.id)[0]}
        this.orders = res.data
        this.per_page = +res?.meta?.pagination?.per_page;
        this.current_page = +res?.meta?.pagination?.current_page;
        this.total = +res?.meta?.pagination?.total;
        this.total_pages = +res?.meta?.pagination?.total_pages;
        this.first = (this.current_page - 1) * this.per_page;
        this.loading = false;
      },
      (err: any)=>{
        this.loading = false;
        this.messageService.clear();
        this.messageService.add({key: 'KeyDashboardSidebar', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    )
  }

  onPageChange(event: any){
    this.loading = true;
    this.getDeliveriesHistory(event.page+1, this.queryParams);
  }

  submitFilterHistoryOrders(btnS: any){
    const query = []

    if(this.filterHistoryOrdersForm.get('order_number').value){
      query.push("order_number:sw:" + this.filterHistoryOrdersForm.get('order_number').value);
    }

    if(this.filterHistoryOrdersForm.get('status').value){
      query.push("status:" + this.filterHistoryOrdersForm.get('status').value);
    }

    if(this.filterHistoryOrdersForm.get('store').value){
      query.push("store:" + this.filterHistoryOrdersForm.get('store').value);
    }

    if(this.filterHistoryOrdersForm.get('staff').value){
      query.push("staff:" + this.filterHistoryOrdersForm.get('staff').value);
    }

    if(this.filterHistoryOrdersForm.get('start_date').value){
      query.push("created:gte:" + this.filterHistoryOrdersForm.get('start_date').value);
    }

    if(this.filterHistoryOrdersForm.get('end_date').value){
      let date = new Date(this.filterHistoryOrdersForm.get('end_date').value)
      date.setDate(date.getDate() + 1)
      query.push("created:lse:" + date.toISOString().split('T')[0]);
    }
    this.queryParams = ";" + query.join(";")

    this.getDeliveriesHistory(1, this.queryParams)
  }

  refreshData(){
    this.getDeliveriesHistory(1, this.queryParams)
  }

  resetFilter(table: any){
    this.queryParams = "";
    table.clear();
    table.reset();
    this.getDeliveriesHistory();
  }

  selectOrder(order: any){
    this.selectedOrderEvent.emit(order);
  }

  onKeyupEvent(event: any){
    this.storeSubscription?.unsubscribe();
    this.getStores(event.target.value);
  }

  loadReceivers: boolean = false;
  storeSubscription: Subscription = new Subscription();
  async getStores(params = ""){
    this.loadReceivers = true;
    this.storeList = [];
    this.storeSubscription = await this.storeService.searchStoresAutocomplete(params, this.currentHub?.profile?.hub?.region?.id)
                                        .pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        this.loadReceivers = false;
        this.storeList = res?.data;
      },
      (err: any)=> {
        this.loadReceivers = false;
        this.messageService.clear();
        this.messageService.add({key: 'homeMessages', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)})
        this.storeList = [];
      }
    )
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
