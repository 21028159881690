import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StaffService } from 'src/app/services/staff.service';

@Component({
    selector: 'app-search-staff',
    templateUrl: './search-staff.component.html',
    styleUrls: ['./search-staff.component.scss'],
    standalone: false
})
export class SearchStaffComponent implements OnInit {

  selectedStaff: any
  loadStaffs: boolean = false;
  staffsList = <any>[];
  @Output() selectedStaffEvent = new EventEmitter<any>();
  destroy$: Subject<boolean> = new Subject<boolean>()

  staffSubscription: any;
  
  constructor(
    private staffService: StaffService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
  }

  getStaff(param: any){
    this.loadStaffs = true;
    this.staffSubscription = this.staffService.filterStaff("name:sw:" + param).pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        let data = res.data;
        this.loadStaffs = false;
        this.staffsList = []
        data.forEach((element: any) => {
          this.staffsList.push({
            label: element.name,
            image: element.image,
            value: element
          })
        });
        //console.log([0].value.image)
      },
      err=> {
        this.loadStaffs = false;
        this.messageService.add({key: 'keyStaffModal', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
        this.staffsList = [];
      }
    )
  }

  onKeyupEvent(event: any){
    if(event.target.value){
      if(this.staffSubscription){
        this.staffSubscription.unsubscribe();
      }
      this.getStaff(event.target.value);
    }else{
      this.staffsList = [];
    }
  }

  emit(){
    if(this.selectedStaff){
      this.selectedStaffEvent.emit(this.selectedStaff.value);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
