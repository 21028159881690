import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { getCurrentHubManager } from 'src/app/ngrx/status/hub-manager/hub-manager.selectors';
import * as fromUser from 'src/app/ngrx/user/user.selectors'

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    standalone: false
})
export class SidenavComponent implements OnInit {

  @Input() sidenav_hidden: boolean = false;
  open_sidenav: boolean = false;
  env = environment;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentHubManager: any = {};
  currentAgent: any;

  constructor(
    private router: Router,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getCurrentHubManager), takeUntil(this.destroy$)).subscribe(
      (hubManager: any) => {
        this.currentHubManager = hubManager;
      }
    )

    this.store.pipe(select(fromUser.getCurrentUser), takeUntil(this.destroy$)).subscribe(
      (user: any) =>{
        this.currentAgent = user;
      }
    )
  }

  ngAfterViewInit(){
    //console.log(this.router.url.endsWith("dashboard"))
  }

}
