import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveriesService {

  constructor(private httpserviceService: HttpserviceService) { }

  updateDataById(data: any, delivery_id: number){
    return this.httpserviceService.post(data, "delivery/" + delivery_id);
  }

  getDeliveries(page: number, params = ""){
    return this.httpserviceService.get("deliveries?page=" + page + params);
  }

  getDeliveryById(id: number){
    return this.httpserviceService.get("delivery/" + id);
  }
}
