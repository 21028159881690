<p-select [style]="{width: '100%'}" 
            [options]="staffsList" 
            [(ngModel)]="selectedStaff" 
            emptyFilterMessage="Aucun résultat trouvé" 
            [optionLabel]="'label'" 
            [filter]="true" filterBy="label"  
            [showClear]="false" [placeholder]="loadStaffs ? 'Loading..' : 'Choisir un template d\'un livreur'" 
            (keyup)="onKeyupEvent($event)" (click)="emit()">
    <ng-template let-item pTemplate="selectedItem">
        <img [src]="item.value?.image" style="width:16px;vertical-align:middle" /> 
        <span style="vertical-align:middle">{{item.label}}</span>
    </ng-template>
  <ng-template let-staff pTemplate="item">
        <div class="ui-helper-clearfix" style="position: relative;">
            <div style="font-size:14px;margin-top:4px">{{staff?.label}}</div>
            <img [src]="staff.value?.image" style="width:24px;position:absolute;top:1px;right:5px"/> 
        </div>
  </ng-template>
</p-select>