<p-table #historyOrdersTable [value]="orders" 
        responsiveLayout="scroll" 
        [scrollable]="true" 
        scrollHeight="calc(100vh - 280px)"
        [loading]="loading">
    <ng-template pTemplate="caption">
        <form [formGroup]="filterHistoryOrdersForm" (ngSubmit)="submitFilterHistoryOrders(btnSFHO)">
            <div class="row">
                <div class="col-12 col-xxl-2 d-flex" style="padding: 0 .1rem 0 .5rem;">
                    <input class="form-control" style="margin-right: .1rem;" type="text" formControlName="order_number" placeholder="N° de commande">
                
                    <select class="form-select" formControlName="status">
                        <option [value]="null">Statut</option>
                        <option value="not-delivered">Non livrée</option>
                        <option value="delivered">Livrée</option>
                        <option value="order_rejected">Rejetée</option>
                        <option value="order_canceled">Annulée</option>
                    </select>
                </div>
                <div class="col-12 col-xxl-2" style="padding: 0 .1rem 0 .1rem;">
                    <p-select class="deliveryList w-100" [options]="(storeList || [])" formControlName="store" 
                                optionLabel="name" optionValue="id" placeholder="Etablissement" 
                                [filter]="true" [emptyFilterMessage]="loadReceivers ? 'Loading..' : 'Aucun résultat trouvé'" 
                                emptyMessage="Aucun store" (keyup)="onKeyupEvent($event)">
                        <ng-template let-delivery pTemplate="item">
                            {{delivery?.name}}
                        </ng-template>
                    </p-select>
                </div>
                <div class="col-12 col-xxl-2" style="padding: 0 .1rem 0 .1rem;">
                    <p-select class="deliveryList w-100" [options]="(staffList || [])" formControlName="staff" optionLabel="name" optionValue="id" 
                                placeholder="Livreur" [filter]="true" emptyFilterMessage="Aucun livreur trouvé" emptyMessage="Aucun livreur">
                        <ng-template let-delivery pTemplate="item">
                            {{delivery?.name}}
                        </ng-template>
                    </p-select>
                </div>
                <div class="col-12 col-xxl-6 d-flex align-items-center" style="padding: 0 .1rem 0 .1rem;">
                    <input class="form-control" type="date" formControlName="start_date">
                <!-- </div>
                <div class="col-3 d-flex align-items-center"> -->
                    &nbsp; <span>Au:</span> &nbsp;
                    <input class="form-control" type="date" formControlName="end_date">
                
                    <button type="reset" class="btn btn-default-primeng-green mx-1" (click)="resetFilter(historyOrdersTable)">Réinitialiser</button>
                    <button #btnSFHO type="submit" class="btn btn-success-fbeeper">Filtrer</button>
                </div>
            </div>
        </form>
    </ng-template>
    <!-- <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col style="width: 20%">
            <col style="width: 20%">
            <col style="width: 25%">
            <col style="width: 15%">
            <col style="width: 20%">
        </colgroup>
    </ng-template> -->
    <ng-template pTemplate="header">
        <tr>
            <th style="min-width: 5%; text-align: center"></th>
            <th style="min-width: 10%" class="text-center" pSortableColumn="status">
                <div class="p-d-flex p-jc-between p-ai-center">
                    Statut
                    <p-sortIcon field="status"></p-sortIcon>
                </div>
            </th>
            <th style="min-width: 5%"></th>
            <th style="min-width: 20%" class="text-start" pSortableColumn="order.order_number">
                <div class="p-d-flex p-jc-between p-ai-center">
                    Num
                    <p-sortIcon field="order.order_number"></p-sortIcon>
                </div>
            </th>
            <th style="min-width: 25%" class="text-start" pSortableColumn="order.store.name">
                <div class="p-d-flex p-jc-between p-ai-center">
                    Resto
                    <p-sortIcon field="order.store.name"></p-sortIcon>
                </div>
            </th>
            <th style="min-width: 15%" class="text-start text-md-center">Prix</th>
            <th style="min-width: 20%" class="text-end">Durée</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order>
        <tr style="cursor: pointer;"
            (click)="selectOrder(order)"
            [ngClass]="{'selectedOrder': selectedOrder?.id == order?.id}">
            <td style="min-width: 5%; text-align: center">
                <span *ngIf="order?.order?.cancel_reason?.key == 0" [title]="order?.order?.status_comment" [style]="{'color': order?.order?.cancel_reason?.color}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-lock-fill" viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
                        <path d="m8 3.293 4.72 4.72a3 3 0 0 0-2.709 3.248A2 2 0 0 0 9 13v2H3.5A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
                        <path d="M13 9a2 2 0 0 0-2 2v1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1v-1a2 2 0 0 0-2-2Zm0 1a1 1 0 0 1 1 1v1h-2v-1a1 1 0 0 1 1-1Z"/>
                    </svg>
                </span>
                <span *ngIf="order?.order?.cancel_reason?.key == 1" [title]="order?.order?.status_comment" [style]="{'color': order?.order?.cancel_reason?.color}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-x-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm9.261 1.135a.5.5 0 0 1 .708 0L13 2.793l1.146-1.147a.5.5 0 0 1 .708.708L13.707 3.5l1.147 1.146a.5.5 0 0 1-.708.708L13 4.207l-1.146 1.147a.5.5 0 0 1-.708-.708L12.293 3.5l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </span>
                <span *ngIf="order?.order?.cancel_reason?.key == 2" [title]="order?.order?.status_comment" [style]="{'color': order?.order?.cancel_reason?.color}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed-fill" viewBox="0 0 16 16">
                        <path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>
                </span>
                <span *ngIf="order?.order?.cancel_reason?.key == 3" [title]="order?.order?.status_comment" [style]="{'color': order?.order?.cancel_reason?.color}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-x-fill" viewBox="0 0 16 16">
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7.354 5.646 8.5 6.793l1.146-1.147a.5.5 0 0 1 .708.708L9.207 7.5l1.147 1.146a.5.5 0 0 1-.708.708L8.5 8.207 7.354 9.354a.5.5 0 1 1-.708-.708L7.793 7.5 6.646 6.354a.5.5 0 1 1 .708-.708z"/>
                    </svg>
                </span>
                <span *ngIf="order?.order?.cancel_reason?.key == 4" [title]="order?.order?.status_comment" [style]="{'color': order?.order?.cancel_reason?.color}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill-x" viewBox="0 0 16 16">
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708Z"/>
                    </svg>
                </span>
                <span *ngIf="order?.order?.cancel_reason?.key == 5" [title]="order?.order?.status_comment" [style]="{'color': order?.order?.cancel_reason?.color}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    </svg>
                </span>
            </td>
            <td style="min-width: 20%" class="text-center">
                <span class="p-1 rounded-pill" 
                        [ngClass]="{
                            'bgSuccess':(order.status == 'delivered'),
                            'bgDanger':(order.status != 'delivered')
                        }">
                    {{order | deliveryStatus }}
                </span>
            </td>
            <td style="min-width: 5%; text-align: center">
                <img *ngIf="order?.order?.gold" src="assets/images/gold.svg" alt="Gold" width="20" height="20">
            </td>
            <td style="min-width: 10%">
                <div class="d-flex align-items-center">
                    <svg *ngIf="order?.order?.collected_order" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="var(--green-hub-fbeeper)" class="bi bi-cash-coin me-1 fw-bold" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                        <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                        <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                        <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
                    </svg>
                    <svg  *ngIf="order?.order?.created_by_store"  xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-shop me-1 fw-bold" viewBox="0 0 16 16">
                        <title>créer par le resto</title>
                        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
                    </svg>
                    {{order?.order?.order_number?.split('-')[0]}}
                </div>
                <p-rating *ngIf="order?.rating" [ngModel]="order?.rating?.rating" [readonly]="true" [stars]="5"></p-rating>
            </td>
            <td style="min-width: 25%">
                {{order?.order?.store?.name}}
            </td>
            <td style="min-width: 15%" class="text-center">
                {{order?.full_price | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
            </td>
            <td style="min-width: 20%" class="text-end">
                {{otherService.avgDeliveryTime(+order?.real_delivery_time * 1000)}}
                <!-- {{otherService.getTimeDifference(order?.real_delivery_time, "timer")}} -->
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6" class="text-center">Aucune commande trouvé.</td>
        </tr>
    </ng-template>
</p-table>
<div class="row">
    <div class="col-12">
        <p-paginator [rows]="per_page" [totalRecords]="total" [first]="first" (onPageChange)="onPageChange($event)"></p-paginator>
    </div>
</div>