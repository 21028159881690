<div class="row">
    <div class="col-12">
        <p-table #storesTable [value]="stores" [globalFilterFields]="['name']" 
                responsiveLayout="scroll" [scrollable]="true" scrollHeight="calc(100vh - 225px)">
            <ng-template pTemplate="caption">
                <div class="row">
                    <div class="col-4">
                        <input #searchFilter class="form-control" type="text" (input)="applyFilterGlobal($event, 'contains', storesTable)" placeholder="Rechercher par resto" />
                    </div>
                    <div class="offset-3 col-5 text-end">
                        <button class="btn btn-default-primeng-green" (click)="resetTable(storesTable);">Réinitialiser</button>
                    </div>
                </div>
            </ng-template>
            <!-- <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col style="width: 10%">
                    <col style="width: 30%">
                    <col style="width: 30%">
                    <col style="width: 10%">
                    <col style="width: 20%">
                </colgroup>
            </ng-template> -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 10% !important" class="text-center"></th>
                    <th style="min-width: 20% !important" class="text-start" pSortableColumn="name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Resto
                            <p-sortIcon field="name"></p-sortIcon>
                        </div>
                    </th>
                    <th style="min-width: 25% !important"> Tel </th>
                    <th style="min-width: 25% !important" class="text-center" colspan="2">
                        Action
                    </th>
                    <th style="min-width: 20% !important" class="text-center">
                        Statut
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-store>
                <tr>
                    <td style="min-width: 10% !important" class="text-center">
                        <a  style="color: var(--info-hub-fbeeper); text-decoration: none;"
                            [href]="this.sanitization.bypassSecurityTrustUrl('sip:' + (store?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix))">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--success-hub-fbeeper)" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16" style="cursor: pointer;">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </a>
                    </td>
                    <td style="min-width: 20% !important">
                        {{store?.name}}
                    </td>
                    <td style="min-width: 25% !important">
                        <app-dropdown-additional-phones [phone]="(store?.phone)?.replace(phoneCallRegex, this.currentUserLocation?.phone_local_prefix)"
                                                        [phonesList]="store?.data?.additional_phones"
                                                        (openAdditionalPhonesModalEvent)="openAdditionalPhonesModal(store)">
                        </app-dropdown-additional-phones>
                    </td>
                    <td style="min-width: 5% !important">
                        <div title="Demander un livreur">
                            <svg style="cursor: pointer;" width="18" height="18" viewBox="0 0 18 18" 
                                fill="var(--success-hub-fbeeper)" xmlns="http://www.w3.org/2000/svg"
                                (click)="selectedStore = store; requestDeliveryManModal = true">
                                <path d="M15 1.5899C14.3333 1.5899 13.6667 1.8399 13.25 2.3399C12.8333 2.8399 12.5 3.42324 12.5 4.0899C12.5 4.75657 12.75 5.42324 13.25 5.8399C13.75 6.25657 14.3333 6.5899 15 6.5899C15.6667 6.5899 16.3333 6.3399 16.75 5.8399C17.1667 5.3399 17.5 4.75657 17.5 4.0899C17.5 2.67324 16.4167 1.5899 15 1.5899ZM16.6667 4.42324H15.3333V5.75657H14.6667V4.42324H13.3333V3.75657H14.6667V2.42324H15.3333V3.75657H16.6667V4.42324Z" fill="var(--success-hub-fbeeper)"/>
                                <path d="M2.66204 5.5899C4.3019 5.5899 4.95135 5.5899 6.53103 5.5899C6.65164 5.5899 6.74951 5.68771 6.74951 5.80832V9.24035C6.74951 9.36106 6.65164 9.45877 6.53103 9.45877H2.66204C2.54146 9.45877 2.44368 9.36109 2.44368 9.24035V5.80829C2.44368 5.68771 2.54146 5.5899 2.66204 5.5899Z" fill="var(--success-hub-fbeeper)"/>
                                <path d="M4.90227 17.4102C5.87923 17.4102 6.67407 16.6154 6.67407 15.6382C6.67407 14.6611 5.87923 13.8662 4.90227 13.8662C3.92506 13.8662 3.13015 14.6611 3.13015 15.6382C3.13015 16.6154 3.92509 17.4102 4.90227 17.4102ZM4.90227 14.7592C5.38686 14.7592 5.7813 15.1536 5.7813 15.6382C5.7813 16.1228 5.38686 16.5173 4.90227 16.5173C4.41752 16.5173 4.02317 16.1228 4.02317 15.6382C4.0232 15.1536 4.41752 14.7592 4.90227 14.7592Z" fill="var(--success-hub-fbeeper)"/>
                                <path d="M14.7279 17.4101C15.7049 17.4101 16.4999 16.6153 16.4999 15.6381C16.4999 14.661 15.7049 13.8661 14.7279 13.8661C13.7509 13.8661 12.956 14.661 12.956 15.6381C12.956 16.6153 13.7509 17.4101 14.7279 17.4101ZM14.7279 14.7591C15.2127 14.7591 15.607 15.1535 15.607 15.6381C15.607 16.1227 15.2127 16.5172 14.7279 16.5172C14.2432 16.5172 13.8489 16.1227 13.8489 15.6381C13.8489 15.1535 14.2432 14.7591 14.7279 14.7591Z" fill="var(--success-hub-fbeeper)"/>
                                <path d="M4.05627 12.1704V11.6848H2.82113C2.54164 11.6848 2.31502 11.4584 2.31502 11.1789V10.8187C2.31502 10.5394 2.54164 10.3129 2.82113 10.3129H9.01169C9.29115 10.3129 9.51777 10.5394 9.51777 10.8187V11.1843C9.51777 11.4637 9.29115 11.6902 9.01169 11.6902H8.32723V13.6347H11.7566C12.0078 13.2429 12.3372 12.9059 12.7227 12.6456L11.3737 7.7806H10.127C9.84753 7.7806 9.62106 7.55394 9.62106 7.27454C9.62106 6.99521 9.84753 6.76868 10.127 6.76868C10.127 6.76868 11.4387 6.76868 11.8991 6.76868C11.9621 6.76868 12.0719 6.7951 12.1257 6.82164C12.2037 6.86051 12.2718 6.91923 12.3215 6.99389L15.9114 12.3759C16.0184 12.5336 16.0973 12.7317 16.0383 12.9057C15.9466 13.1761 15.6365 13.2728 15.3188 13.1786C15.0775 13.1071 14.8772 13.0757 14.6226 13.0757C13.3045 13.0757 12.2322 14.1481 12.2322 15.4663C12.2322 15.7457 12.0058 15.9723 11.7263 15.9723H7.79883C7.51937 15.9723 7.29278 15.7457 7.29278 15.4663C7.29278 14.1481 6.22059 13.0757 4.90252 13.0757C3.58444 13.0757 2.51201 14.1481 2.51201 15.4663C2.51201 15.7457 2.28535 15.9723 2.00608 15.9723C1.72649 15.9723 1.50006 15.7457 1.50006 15.4663C1.50006 13.8822 2.58832 12.5475 4.05627 12.1704Z" fill="var(--success-hub-fbeeper)"/>
                            </svg>
                        </div>
                    </td>
                    <td style="min-width: 20% !important" class="text-center">
                        <div class="row p-0 w-100 m-0 d-flex justify-content-between">
                            <div class="col-12 col-md-4 p-0 text-center">
                                <span title="Fermé">
                                    <svg *ngIf="!store?.isOpen" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="var(--danger-hub-fbeeper)" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="col-12 col-md-4 p-0 text-center">
                                <span title="Envoyer une réclamation à l'administration" (click)="selectedStore = store; storeTicket = true;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="orange" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16"  style="cursor: pointer;">
                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="col-12 col-md-4 p-0 text-center">
                                <span *ngIf="store?.accept_delivery" title="Mettre en pause" style="cursor: pointer;" (click)="openPauseStoreModal(store)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="var(--success-hub-fbeeper)" class="bi bi-pause-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/>
                                    </svg>
                                </span>
                                <span #btnSSS *ngIf="!store?.accept_delivery" [title]="store?.accept_delivery_at + ' - Démarer'" 
                                    (click)="submitStartStore(btnSSS, store)">
                                    <svg style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="orange" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="d-flex justify-content-between">
                            
                            
                            
                        </div> -->
                    </td>
                    <td style="min-width: 20% !important" class="text-center">
                        <span [ngStyle]="{'color': store?.online ? 'var(--success-hub-fbeeper)' : 'var(--danger-hub-fbeeper)'}">
                            {{store?.online ? 'En ligne' : 'Hors-ligne'}}
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5" class="text-center">Aucun restaurant.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<app-additional-phones  *ngIf="additionalPhonesModal"
                        [additionalPhonesModal]="additionalPhonesModal"
                        [type]="selectedType"
                        [dataTable]="selectedStore?.data?.additional_phones"
                        [targetId]="selectedStore?.id"
                        [additionalPhonesChanges]="currentStores"
                        (additionalPhonesModalEvent)="additionalPhonesModalEvent()">
</app-additional-phones>
<app-store-ticket   *ngIf="storeTicket"
                    [storeTicket]="storeTicket"
                    [store_id]="selectedStore?.id"
                    (storeTicketEvent)="changeStoreTicket($event)">
</app-store-ticket>

<!-- Pause store Modal -->
<p-dialog header="Mettre l'etablissement en pause" [(visible)]="pauseStoreModal" [modal]="true" [style]="{width: '35vw'}" 
        [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true" (onHide)="selectedStore = null; pauseStoreForm.resetForm()">
    <p-toast key="KeyPauseStore" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}" [baseZIndex]="5000"></p-toast>
    <form #pauseStoreForm="ngForm" (ngSubmit)="submitPauseStore(btnSPS, pauseStoreForm)">
        <div class="row">
            <div class="col-12">
                <label> <b>Retour</b></label>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="10m" id="accept_delivery_at_10m" name="accept_delivery_at" ngModel required />
                    <label class="form-check-label" for="accept_delivery_at_10m">
                      10 minutes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="30m" id="accept_delivery_at_30m" name="accept_delivery_at" ngModel>
                    <label class="form-check-label" for="accept_delivery_at_30m">
                      30 minutes
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="1h" id="accept_delivery_at_1h" name="accept_delivery_at" ngModel>
                    <label class="form-check-label" for="accept_delivery_at_1h">
                      1 heure
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="3h" id="accept_delivery_at_3h" name="accept_delivery_at" ngModel>
                    <label class="form-check-label" for="accept_delivery_at_3h">
                      3 heure
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="1d" id="accept_delivery_at_1d" name="accept_delivery_at" ngModel>
                    <label class="form-check-label" for="accept_delivery_at_1d">
                      Demain
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="autre" id="accept_delivery_at_1d" name="accept_delivery_at" ngModel>
                    <label class="form-check-label" for="accept_delivery_at_1d">
                      Autre
                    </label>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mt-2">
                            <div class="form-check form-check-inline d-flex align-items-center">
                                <input class="form-check-input me-2" type="radio" name="accept_delivery_at" ngModel id="accept_delivery_at_today" value="today">
                                <label class="form-check-label" for="accept_delivery_at_today">Aujourd'hui</label>
                                <p-datepicker [style]="{width: '150px'}" name="accept_delivery_at_time" 
                                            [stepMinute]="15" [timeOnly]="true" [inline]="true"
                                            [(ngModel)]="initDate">
                                </p-datepicker>
                            </div>
                          </div>
                    </div>
                </div>
                <ng-container *ngIf="pauseStoreForm.value.accept_delivery_at == 'autre'">
                    <div class="row">
                        <div class="col-12">
                            <!-- <label for="changeStatus"><b> Raison </b><span class="text-danger">*</span></label> -->
                            <select class="form-select" aria-label="Default select" 
                                    name="accept_delivery_on" ngModel required>
                                <option value="2jrs">2 jours</option>
                                <option value="3jrs">3 jours</option>
                                <option value="4jrs">4 jours</option>
                                <option value="5jrs">5 jours</option>
                                <option value="6jrs">6 jours</option>
                                <option value="7jrs">7 jours</option>
                                <option value="autre">Autre</option>
                            </select>
                        </div>
                    </div>
                </ng-container>
                <!-- <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" value="undetermined" id="accept_delivery_at_undetermined" 
                           name="accept_delivery_at" ngModel>
                    <label class="form-check-label" for="accept_delivery_at_undetermined">
                        Indéterminé
                    </label>
                </div> -->
            </div>
            <div class="row mt-2"  *ngIf="!pauseStoreForm.value.accept_delivery_on">
                <div class="col-12">
                    <label><b> Réclamation</b></label><br>
                    <div class="form-check">
                        <input  class="form-check-input" type="checkbox" value="true" id="send-store-ticket" name="store_ticket" ngModel>
                        <label class="form-check-label" for="send-store-ticket">
                          Envoyer une réclamation à l'administration
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mt-2"  *ngIf="pauseStoreForm.value.accept_delivery_on">
                <div class="col-12">
                    <label><b> Réclamation</b></label><br>
                    <div class="form-check">
                        <input  class="form-check-input" type="checkbox" value="true" id="send-store-ticket" name="store_ticket" [(ngModel)]="isChecked" required>
                        <label class="form-check-label" for="send-store-ticket">
                          Envoyer une réclamation à l'administration
                        </label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="pauseStoreForm.value.store_ticket">
                <div class="row">
                    <div class="col-12">
                        <label for="changeStatus"><b> Raison </b><span class="text-danger">*</span></label>
                        <select id="changeStatus" class="form-select" aria-label="Default select" 
                                name="reasonValue" ngModel required>
                            <option value="" disabled selected hidden>Choisir la raison</option>
                            <option value="Établissement injoignable">Établissement injoignable</option>
                            <option value="Établissement ne répond pas">Établissement ne répond pas</option>
                            <option value="Établissement Fermé jusqu`à">Établissement Fermé jusqu'à </option>
                            <option value="Établissement Fermé indéfiniment">Établissement Fermé indéfiniment</option>
                            <option value="Résiliation de contrat">Résiliation de contrat</option>
                            <option value="Autre">Autre</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="pauseStoreForm.value.reasonValue == 'Établissement Fermé jusqu`à'">
                    <div class="col-12">
                        <input type="date" class="form-control" name="until" id="close_until" ngModel required>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="pauseStoreForm.value.reasonValue == 'Autre'">
                    <div class="col-12">
                        <label for="storeTicketDetails">Plus détails</label>
                        <textarea class="form-control" id="storeTicketDetails" rows="2" name="store_ticket_details" ngModel required></textarea>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn btn-default-fbeeper me-2" (click)="pauseStoreModal = false;">Annuler</button>
                <!-- <button #btnSPSU type="button" class="btn btn-default-primeng-green me-2" (click)="submitPauseStoreUndetermined(btnSPSU);">
                    <div *ngIf="btnSPSU.disabled" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Indéterminé
                </button> -->
                <button #btnSPS type="submit" class="btn btn-success-fbeeper" [disabled]="pauseStoreForm.invalid">
                    <div *ngIf="btnSPS.disabled && pauseStoreForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Envoyer
                </button>
            </div>
        </div>
    </form>
</p-dialog>

<p-dialog header="Envoyer un livreur" [(visible)]="requestDeliveryManModal" [modal]="true" [style]="{width: '35vw'}" 
        [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true" (onHide)="selectedStore = null; requestDeliveryManForm.resetForm()">
    <p-toast key="KeyRequestDeliveryMan" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}" [baseZIndex]="5000"></p-toast>
    <form #requestDeliveryManForm="ngForm" (ngSubmit)="submitRequestDeliveryMan(btnSRDM, requestDeliveryManForm)">

        <div class="row">
            <div class="col-12">
                <p>Envoyer un livreur à <b>{{selectedStore?.name}}</b></p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 text-end">
                <button class="btn btn-default-fbeeper me-2" (click)="requestDeliveryManModal = false;">Annuler</button>
                <button #btnSRDM type="submit" class="btn btn-success-fbeeper" [disabled]="requestDeliveryManForm.invalid">
                    <div *ngIf="btnSRDM.disabled && requestDeliveryManForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Envoyer
                </button>
            </div>
        </div>
    </form>

</p-dialog>