import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class StoreTicketService {

  constructor(private httpserviceService: HttpserviceService) { }

  createStoreTicket(data: any){
    return this.httpserviceService.post(data, "store_tickets");
  }
  
  updateStoreTicketById(data: any, store_ticket_id: number){
    return this.httpserviceService.post(data, "store_tickets/" + store_ticket_id);
  }

  getStoreTicketsTypes(){
    return this.httpserviceService.get("store_tickets/types");
  }
}
